import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { DocumentationCollectionSegment, LogEntriesCollectionSegment, LogEntry, QueryLogEntriesArgs } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_DOCUMENTATION = gql`
  query GetLazyDocumentation(
    $filterInput: DocumentationFilterInput, 
    $order: [DocumentationDtoSortInput!]
  ) {
    documentation(filterInput: $filterInput, order: $order) {
      items {
        description
        docTitle
        fileType
        id
        libType
        number
        showInCentral
      }
    }
  }
`

interface LazyDocumentationData {
  documentation: DocumentationCollectionSegment;
}

interface LazyDocumentationResult {
  getLazyDocumentation: LazyQueryExecFunction<LazyDocumentationData, OperationVariables>;
}

export const useGetLazyDocumentation = ({ filterInput, order }: QueryLogEntriesArgs): LazyDocumentationResult => {

  const [ getDocumentation, { error }] = useLazyQuery(GET_LAZY_DOCUMENTATION, {
    fetchPolicy: "network-only",
    variables: { filterInput, order },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyDocumentation').error('Error fetching documentation -->', error.message);
  }

  return {
    getLazyDocumentation: getDocumentation
  };
};