export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
};

export type AddCommentInput = {
  content: Scalars['String'];
  recordId: Scalars['String'];
  recordType: RecordType;
  parentId?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER',
  Validation = 'VALIDATION'
}

export type Attachment = {
  __typename?: 'Attachment';
  pKey?: Maybe<Scalars['String']>;
  docTitle: Scalars['String'];
  fldDescription?: Maybe<Scalars['String']>;
  fldShowInCentral?: Maybe<Scalars['Boolean']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  fldLibType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  fldRevision?: Maybe<Scalars['String']>;
  fldRevisionDate?: Maybe<Scalars['DateTime']>;
  fldAltPath?: Maybe<Scalars['Int']>;
  fldFileName?: Maybe<Scalars['String']>;
  fldNotes?: Maybe<Scalars['String']>;
  fldPage?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  attachmentType: AttachmentType;
  crossReferenceId?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
};

export type AttachmentCrossReferenceInput = {
  recordId: Scalars['String'];
  attachmentId: Scalars['String'];
};

export type AttachmentInput = {
  pKey?: Maybe<Scalars['String']>;
  docTitle: Scalars['String'];
  fldDescription?: Maybe<Scalars['String']>;
  fldShowInCentral?: Maybe<Scalars['Boolean']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  fldLibType?: Maybe<Scalars['String']>;
  fldRevision?: Maybe<Scalars['String']>;
  fldRevisionDate?: Maybe<Scalars['DateTime']>;
  fldAltPath?: Maybe<Scalars['Int']>;
  fldFileName?: Maybe<Scalars['String']>;
  fldNotes?: Maybe<Scalars['String']>;
  fldPage?: Maybe<Scalars['String']>;
  recordId: Scalars['String'];
  recordType: RecordType;
};

export type AttachmentPayload = {
  __typename?: 'AttachmentPayload';
  attachment?: Maybe<Attachment>;
  errors?: Maybe<Array<UserError>>;
};

export type AttachmentSortInput = {
  pKey?: Maybe<SortEnumType>;
  docTitle?: Maybe<SortEnumType>;
  fldDescription?: Maybe<SortEnumType>;
  fldShowInCentral?: Maybe<SortEnumType>;
  fldSrhkey?: Maybe<SortEnumType>;
  fldLibType?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  fldRevision?: Maybe<SortEnumType>;
  fldRevisionDate?: Maybe<SortEnumType>;
  fldAltPath?: Maybe<SortEnumType>;
  fldFileName?: Maybe<SortEnumType>;
  fldNotes?: Maybe<SortEnumType>;
  fldPage?: Maybe<SortEnumType>;
  fileExtension?: Maybe<SortEnumType>;
  attachmentType?: Maybe<SortEnumType>;
  crossReferenceId?: Maybe<SortEnumType>;
  recordId?: Maybe<SortEnumType>;
  recordType?: Maybe<SortEnumType>;
};

export enum AttachmentType {
  Document = 'DOCUMENT',
  Photo = 'PHOTO',
  Unknown = 'UNKNOWN'
}

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};


export type ByteOperationFilterInput = {
  eq?: Maybe<Scalars['Byte']>;
  neq?: Maybe<Scalars['Byte']>;
  in?: Maybe<Array<Maybe<Scalars['Byte']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Byte']>>>;
  gt?: Maybe<Scalars['Byte']>;
  ngt?: Maybe<Scalars['Byte']>;
  gte?: Maybe<Scalars['Byte']>;
  ngte?: Maybe<Scalars['Byte']>;
  lt?: Maybe<Scalars['Byte']>;
  nlt?: Maybe<Scalars['Byte']>;
  lte?: Maybe<Scalars['Byte']>;
  nlte?: Maybe<Scalars['Byte']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  authorId: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parent?: Maybe<Comment>;
  recordId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reportId?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  author?: Maybe<CrewProfile>;
  replies: Array<Comment>;
  report?: Maybe<TblAnyReport>;
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

export type CommentSortInput = {
  id?: Maybe<SortEnumType>;
  content?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  authorId?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  parent?: Maybe<CommentSortInput>;
  recordId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  reportId?: Maybe<SortEnumType>;
  recordType?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
  author?: Maybe<CrewProfileSortInput>;
  report?: Maybe<TblAnyReportSortInput>;
};

export type Company = {
  __typename?: 'Company';
  name?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
};

export type CompanyFilterInput = {
  and?: Maybe<Array<CompanyFilterInput>>;
  or?: Maybe<Array<CompanyFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  module?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
};

export type CompanyName = {
  __typename?: 'CompanyName';
  name?: Maybe<Scalars['String']>;
};

export type CompanySortInput = {
  name?: Maybe<SortEnumType>;
  module?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
};

export type Crew = {
  __typename?: 'Crew';
  fldCrewId: Scalars['String'];
  fldFirst?: Maybe<Scalars['String']>;
  fldLast?: Maybe<Scalars['String']>;
  crewUsername?: Maybe<Scalars['String']>;
};

export type CrewProfile = {
  __typename?: 'CrewProfile';
  fldCrewId: Scalars['String'];
  fldCurrent?: Maybe<Scalars['Boolean']>;
  crewPassword?: Maybe<Scalars['String']>;
  noView?: Maybe<Scalars['Boolean']>;
  fldFirst?: Maybe<Scalars['String']>;
  fldMiddle?: Maybe<Scalars['String']>;
  fldLast?: Maybe<Scalars['String']>;
  fldDob?: Maybe<Scalars['DateTime']>;
  fldPosition?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  fldAddress?: Maybe<Scalars['String']>;
  fldPassport?: Maybe<Scalars['String']>;
  fldNationality?: Maybe<Scalars['String']>;
  fldIssue?: Maybe<Scalars['String']>;
  dateIssue?: Maybe<Scalars['DateTime']>;
  dateExpire?: Maybe<Scalars['DateTime']>;
  fldStationBill?: Maybe<Scalars['String']>;
  fldPicture?: Maybe<Scalars['String']>;
  fldDl?: Maybe<Scalars['String']>;
  fldBupa?: Maybe<Scalars['String']>;
  fldReligion?: Maybe<Scalars['String']>;
  fldFood?: Maybe<Scalars['String']>;
  fldPersonalNotes?: Maybe<Scalars['String']>;
  fldHeight?: Maybe<Scalars['Decimal']>;
  fldWeight?: Maybe<Scalars['Decimal']>;
  fldShoeSize?: Maybe<Scalars['Decimal']>;
  fldChest?: Maybe<Scalars['Decimal']>;
  fldNeck?: Maybe<Scalars['Decimal']>;
  fldWaist?: Maybe<Scalars['Decimal']>;
  fldInseam?: Maybe<Scalars['Decimal']>;
  fldHeightMeas?: Maybe<Scalars['String']>;
  fldWeightMeas?: Maybe<Scalars['String']>;
  fldShoeMeas?: Maybe<Scalars['String']>;
  fldChestMeas?: Maybe<Scalars['String']>;
  fldNeckMeas?: Maybe<Scalars['String']>;
  fldWaistMeas?: Maybe<Scalars['String']>;
  fldInseamMeas?: Maybe<Scalars['String']>;
  fldLaundryNum?: Maybe<Scalars['Int']>;
  fldAllergies?: Maybe<Scalars['String']>;
  fldMeds?: Maybe<Scalars['String']>;
  fldMedInstruct?: Maybe<Scalars['String']>;
  fldDiet?: Maybe<Scalars['String']>;
  fldMedNotes?: Maybe<Scalars['String']>;
  fldNok1?: Maybe<Scalars['String']>;
  fldPhone1?: Maybe<Scalars['String']>;
  fldFax1?: Maybe<Scalars['String']>;
  fldNok1mobile?: Maybe<Scalars['String']>;
  fldNok1email?: Maybe<Scalars['String']>;
  fldNok2?: Maybe<Scalars['String']>;
  fldPhone2?: Maybe<Scalars['String']>;
  fldFax2?: Maybe<Scalars['String']>;
  fldNok2mobile?: Maybe<Scalars['String']>;
  fldNok2email?: Maybe<Scalars['String']>;
  dateHire?: Maybe<Scalars['DateTime']>;
  fldSalary?: Maybe<Scalars['Decimal']>;
  fldCurrentPay?: Maybe<Scalars['Decimal']>;
  dateFire?: Maybe<Scalars['DateTime']>;
  fldBank?: Maybe<Scalars['String']>;
  fldAcct?: Maybe<Scalars['String']>;
  fldWire?: Maybe<Scalars['String']>;
  datePayRev?: Maybe<Scalars['DateTime']>;
  fldVacDays?: Maybe<Scalars['Decimal']>;
  fldVacMeas?: Maybe<Scalars['String']>;
  dateVac?: Maybe<Scalars['DateTime']>;
  fldRemarks?: Maybe<Scalars['String']>;
  fldNameupper?: Maybe<Scalars['String']>;
  fldIsGuest?: Maybe<Scalars['Boolean']>;
  fldMarital?: Maybe<Scalars['String']>;
  fldPlaceOfBirth?: Maybe<Scalars['String']>;
  fldBankPhone?: Maybe<Scalars['String']>;
  fldBankFax?: Maybe<Scalars['String']>;
  fldBankEmail?: Maybe<Scalars['String']>;
  fldBankContact?: Maybe<Scalars['String']>;
  fldBankRouting?: Maybe<Scalars['String']>;
  fldBankSwift?: Maybe<Scalars['String']>;
  fldBankIban?: Maybe<Scalars['String']>;
  fldBankCurr?: Maybe<Scalars['String']>;
  departmentHead?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldIsSo?: Maybe<Scalars['Boolean']>;
  fldIsSso?: Maybe<Scalars['Boolean']>;
  fldIsWatchkeeper?: Maybe<Scalars['Boolean']>;
  fldMedConsent?: Maybe<Scalars['Boolean']>;
  fldBloodAbo?: Maybe<Scalars['String']>;
  fldBloodRh?: Maybe<Scalars['String']>;
  fldSeaDis?: Maybe<Scalars['Boolean']>;
  fldCertsOnBoard?: Maybe<Scalars['Boolean']>;
  fldCrewFamiliar?: Maybe<Scalars['DateTime']>;
  fldIsAdmin?: Maybe<Scalars['Boolean']>;
  fldDefaultPassport?: Maybe<Scalars['String']>;
  crewUsername?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  comments: Array<Comment>;
};

export type CrewProfileSortInput = {
  fldCrewId?: Maybe<SortEnumType>;
  fldCurrent?: Maybe<SortEnumType>;
  crewPassword?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  fldFirst?: Maybe<SortEnumType>;
  fldMiddle?: Maybe<SortEnumType>;
  fldLast?: Maybe<SortEnumType>;
  fldDob?: Maybe<SortEnumType>;
  fldPosition?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  fldAddress?: Maybe<SortEnumType>;
  fldPassport?: Maybe<SortEnumType>;
  fldNationality?: Maybe<SortEnumType>;
  fldIssue?: Maybe<SortEnumType>;
  dateIssue?: Maybe<SortEnumType>;
  dateExpire?: Maybe<SortEnumType>;
  fldStationBill?: Maybe<SortEnumType>;
  fldPicture?: Maybe<SortEnumType>;
  fldDl?: Maybe<SortEnumType>;
  fldBupa?: Maybe<SortEnumType>;
  fldReligion?: Maybe<SortEnumType>;
  fldFood?: Maybe<SortEnumType>;
  fldPersonalNotes?: Maybe<SortEnumType>;
  fldHeight?: Maybe<SortEnumType>;
  fldWeight?: Maybe<SortEnumType>;
  fldShoeSize?: Maybe<SortEnumType>;
  fldChest?: Maybe<SortEnumType>;
  fldNeck?: Maybe<SortEnumType>;
  fldWaist?: Maybe<SortEnumType>;
  fldInseam?: Maybe<SortEnumType>;
  fldHeightMeas?: Maybe<SortEnumType>;
  fldWeightMeas?: Maybe<SortEnumType>;
  fldShoeMeas?: Maybe<SortEnumType>;
  fldChestMeas?: Maybe<SortEnumType>;
  fldNeckMeas?: Maybe<SortEnumType>;
  fldWaistMeas?: Maybe<SortEnumType>;
  fldInseamMeas?: Maybe<SortEnumType>;
  fldLaundryNum?: Maybe<SortEnumType>;
  fldAllergies?: Maybe<SortEnumType>;
  fldMeds?: Maybe<SortEnumType>;
  fldMedInstruct?: Maybe<SortEnumType>;
  fldDiet?: Maybe<SortEnumType>;
  fldMedNotes?: Maybe<SortEnumType>;
  fldNok1?: Maybe<SortEnumType>;
  fldPhone1?: Maybe<SortEnumType>;
  fldFax1?: Maybe<SortEnumType>;
  fldNok1mobile?: Maybe<SortEnumType>;
  fldNok1email?: Maybe<SortEnumType>;
  fldNok2?: Maybe<SortEnumType>;
  fldPhone2?: Maybe<SortEnumType>;
  fldFax2?: Maybe<SortEnumType>;
  fldNok2mobile?: Maybe<SortEnumType>;
  fldNok2email?: Maybe<SortEnumType>;
  dateHire?: Maybe<SortEnumType>;
  fldSalary?: Maybe<SortEnumType>;
  fldCurrentPay?: Maybe<SortEnumType>;
  dateFire?: Maybe<SortEnumType>;
  fldBank?: Maybe<SortEnumType>;
  fldAcct?: Maybe<SortEnumType>;
  fldWire?: Maybe<SortEnumType>;
  datePayRev?: Maybe<SortEnumType>;
  fldVacDays?: Maybe<SortEnumType>;
  fldVacMeas?: Maybe<SortEnumType>;
  dateVac?: Maybe<SortEnumType>;
  fldRemarks?: Maybe<SortEnumType>;
  fldNameupper?: Maybe<SortEnumType>;
  fldIsGuest?: Maybe<SortEnumType>;
  fldMarital?: Maybe<SortEnumType>;
  fldPlaceOfBirth?: Maybe<SortEnumType>;
  fldBankPhone?: Maybe<SortEnumType>;
  fldBankFax?: Maybe<SortEnumType>;
  fldBankEmail?: Maybe<SortEnumType>;
  fldBankContact?: Maybe<SortEnumType>;
  fldBankRouting?: Maybe<SortEnumType>;
  fldBankSwift?: Maybe<SortEnumType>;
  fldBankIban?: Maybe<SortEnumType>;
  fldBankCurr?: Maybe<SortEnumType>;
  departmentHead?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  fldIsSo?: Maybe<SortEnumType>;
  fldIsSso?: Maybe<SortEnumType>;
  fldIsWatchkeeper?: Maybe<SortEnumType>;
  fldMedConsent?: Maybe<SortEnumType>;
  fldBloodAbo?: Maybe<SortEnumType>;
  fldBloodRh?: Maybe<SortEnumType>;
  fldSeaDis?: Maybe<SortEnumType>;
  fldCertsOnBoard?: Maybe<SortEnumType>;
  fldCrewFamiliar?: Maybe<SortEnumType>;
  fldIsAdmin?: Maybe<SortEnumType>;
  fldDefaultPassport?: Maybe<SortEnumType>;
  crewUsername?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type CrewSortInput = {
  fldCrewId?: Maybe<SortEnumType>;
  fldFirst?: Maybe<SortEnumType>;
  fldLast?: Maybe<SortEnumType>;
  crewUsername?: Maybe<SortEnumType>;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['String'];
  curr: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbolBefore?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type CurrencySortInput = {
  id?: Maybe<SortEnumType>;
  curr?: Maybe<SortEnumType>;
  abbreviation?: Maybe<SortEnumType>;
  symbol?: Maybe<SortEnumType>;
  symbolBefore?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};


export type DateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};


export type DecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
};

export type DeleteLogEntryPayload = {
  __typename?: 'DeleteLogEntryPayload';
  logEntry?: Maybe<LogEntry>;
  errors?: Maybe<Array<UserError>>;
};

export type Documentation = {
  __typename?: 'Documentation';
  id: Scalars['String'];
  docTitle: Scalars['String'];
  received?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  docPage?: Maybe<Scalars['String']>;
  docFigure?: Maybe<Scalars['String']>;
  addedBy?: Maybe<Scalars['String']>;
  toc?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  specRef?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  smsReport?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  libType?: Maybe<Scalars['String']>;
  completeReqd?: Maybe<Scalars['Boolean']>;
  verifiedReq?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

/** A segment of a collection. */
export type DocumentationCollectionSegment = {
  __typename?: 'DocumentationCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Documentation>>;
  totalCount: Scalars['Int'];
};

export type DocumentationDtoSortInput = {
  id?: Maybe<SortEnumType>;
  docTitle?: Maybe<SortEnumType>;
  received?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  docPage?: Maybe<SortEnumType>;
  docFigure?: Maybe<SortEnumType>;
  addedBy?: Maybe<SortEnumType>;
  toc?: Maybe<SortEnumType>;
  number?: Maybe<SortEnumType>;
  specRef?: Maybe<SortEnumType>;
  showInCentral?: Maybe<SortEnumType>;
  smsReport?: Maybe<SortEnumType>;
  password?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  libType?: Maybe<SortEnumType>;
  completeReqd?: Maybe<SortEnumType>;
  verifiedReq?: Maybe<SortEnumType>;
  fileType?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  statusInternal?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type DocumentationFilterInput = {
  showInCentral?: Maybe<Scalars['Boolean']>;
  libType?: Maybe<DocumentationLibType>;
  docTitle?: Maybe<Scalars['String']>;
};

export enum DocumentationLibType {
  Manuals = 'MANUALS',
  Drawings = 'DRAWINGS',
  Msds = 'MSDS',
  Photos = 'PHOTOS',
  Smsdocs = 'SMSDOCS',
  Ispsdocs = 'ISPSDOCS'
}

export type Equipment = {
  __typename?: 'Equipment';
  eqKey: Scalars['String'];
  uniqueName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  fldHoursKey?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Int']>;
  fldParent?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  powerRequired?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  voltage?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Decimal']>;
  weightQty?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  searchMod?: Maybe<Scalars['String']>;
  searchMaker?: Maybe<Scalars['String']>;
  serialNum?: Maybe<Scalars['String']>;
  artNumber?: Maybe<Scalars['String']>;
  arrangementNum?: Maybe<Scalars['String']>;
  drawingRef?: Maybe<Scalars['String']>;
  circuitNumber?: Maybe<Scalars['String']>;
  breaker?: Maybe<Scalars['String']>;
  distBoard?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  fldCountHours?: Maybe<Scalars['Boolean']>;
  fldHoursOffset?: Maybe<Scalars['Int']>;
  fldDateReset?: Maybe<Scalars['DateTime']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  fldExpireDate?: Maybe<Scalars['DateTime']>;
  fldDay?: Maybe<Scalars['Byte']>;
  fldMonth?: Maybe<Scalars['String']>;
  fldYear?: Maybe<Scalars['String']>;
  fldRestricted?: Maybe<Scalars['Boolean']>;
  fldHrsEst?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldInService?: Maybe<Scalars['DateTime']>;
  fldReportingType?: Maybe<Scalars['String']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  inventory: Array<Inventory>;
};

export type EquipmentFilterInput = {
  and?: Maybe<Array<EquipmentFilterInput>>;
  or?: Maybe<Array<EquipmentFilterInput>>;
  eqKey?: Maybe<StringOperationFilterInput>;
  uniqueName?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  fldHoursKey?: Maybe<StringOperationFilterInput>;
  hours?: Maybe<IntOperationFilterInput>;
  fldParent?: Maybe<StringOperationFilterInput>;
  amount?: Maybe<IntOperationFilterInput>;
  manufacturer?: Maybe<StringOperationFilterInput>;
  modelNumber?: Maybe<StringOperationFilterInput>;
  rating?: Maybe<StringOperationFilterInput>;
  powerRequired?: Maybe<StringOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  voltage?: Maybe<StringOperationFilterInput>;
  weight?: Maybe<DecimalOperationFilterInput>;
  weightQty?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
  qty?: Maybe<StringOperationFilterInput>;
  searchMod?: Maybe<StringOperationFilterInput>;
  searchMaker?: Maybe<StringOperationFilterInput>;
  serialNum?: Maybe<StringOperationFilterInput>;
  artNumber?: Maybe<StringOperationFilterInput>;
  arrangementNum?: Maybe<StringOperationFilterInput>;
  drawingRef?: Maybe<StringOperationFilterInput>;
  circuitNumber?: Maybe<StringOperationFilterInput>;
  breaker?: Maybe<StringOperationFilterInput>;
  distBoard?: Maybe<StringOperationFilterInput>;
  supplier?: Maybe<StringOperationFilterInput>;
  fldCountHours?: Maybe<BooleanOperationFilterInput>;
  fldHoursOffset?: Maybe<IntOperationFilterInput>;
  fldDateReset?: Maybe<DateTimeOperationFilterInput>;
  fldSms?: Maybe<BooleanOperationFilterInput>;
  fldLocHierarchy?: Maybe<StringOperationFilterInput>;
  fldExpireDate?: Maybe<DateTimeOperationFilterInput>;
  fldDay?: Maybe<ByteOperationFilterInput>;
  fldMonth?: Maybe<StringOperationFilterInput>;
  fldYear?: Maybe<StringOperationFilterInput>;
  fldRestricted?: Maybe<BooleanOperationFilterInput>;
  fldHrsEst?: Maybe<IntOperationFilterInput>;
  dateEntered?: Maybe<DateTimeOperationFilterInput>;
  fldInService?: Maybe<DateTimeOperationFilterInput>;
  fldReportingType?: Maybe<StringOperationFilterInput>;
  fldSrhkey?: Maybe<StringOperationFilterInput>;
  deletedAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  isRecoverable?: Maybe<BooleanOperationFilterInput>;
  autoId?: Maybe<IntOperationFilterInput>;
  inventory?: Maybe<ListFilterInputTypeOfInventoryFilterInput>;
};

/** A segment of a collection. */
export type EquipmentPagedCollectionSegment = {
  __typename?: 'EquipmentPagedCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Equipment>>;
  totalCount: Scalars['Int'];
};

export type EquipmentSortInput = {
  eqKey?: Maybe<SortEnumType>;
  uniqueName?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  fldHoursKey?: Maybe<SortEnumType>;
  hours?: Maybe<SortEnumType>;
  fldParent?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  modelNumber?: Maybe<SortEnumType>;
  rating?: Maybe<SortEnumType>;
  powerRequired?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  voltage?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  weightQty?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  qty?: Maybe<SortEnumType>;
  searchMod?: Maybe<SortEnumType>;
  searchMaker?: Maybe<SortEnumType>;
  serialNum?: Maybe<SortEnumType>;
  artNumber?: Maybe<SortEnumType>;
  arrangementNum?: Maybe<SortEnumType>;
  drawingRef?: Maybe<SortEnumType>;
  circuitNumber?: Maybe<SortEnumType>;
  breaker?: Maybe<SortEnumType>;
  distBoard?: Maybe<SortEnumType>;
  supplier?: Maybe<SortEnumType>;
  fldCountHours?: Maybe<SortEnumType>;
  fldHoursOffset?: Maybe<SortEnumType>;
  fldDateReset?: Maybe<SortEnumType>;
  fldSms?: Maybe<SortEnumType>;
  fldLocHierarchy?: Maybe<SortEnumType>;
  fldExpireDate?: Maybe<SortEnumType>;
  fldDay?: Maybe<SortEnumType>;
  fldMonth?: Maybe<SortEnumType>;
  fldYear?: Maybe<SortEnumType>;
  fldRestricted?: Maybe<SortEnumType>;
  fldHrsEst?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  fldInService?: Maybe<SortEnumType>;
  fldReportingType?: Maybe<SortEnumType>;
  fldSrhkey?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type GridSavedViewInput = {
  pkey?: Maybe<Scalars['String']>;
  fldCrewId?: Maybe<Scalars['String']>;
  fldLayout: Scalars['String'];
  fldModule: Scalars['String'];
  fldViewName: Scalars['String'];
};

export type GridSavedViewPayload = {
  __typename?: 'GridSavedViewPayload';
  view?: Maybe<TblGridSavedView>;
  errors?: Maybe<Array<UserError>>;
};

export type GroupCountOfTblLocationsHierarchy = {
  __typename?: 'GroupCountOfTblLocationsHierarchy';
  item?: Maybe<TblLocationsHierarchy>;
  count: Scalars['Int'];
};

export type GroupCountOfTblSystemsHierarchy = {
  __typename?: 'GroupCountOfTblSystemsHierarchy';
  item?: Maybe<TblSystemsHierarchy>;
  count: Scalars['Int'];
};

export type GroupListOfEquipmentAndLogEntry = {
  __typename?: 'GroupListOfEquipmentAndLogEntry';
  item?: Maybe<Equipment>;
  list?: Maybe<Array<Maybe<LogEntry>>>;
  count: Scalars['Int'];
};

export type InspectionQueries = {
  __typename?: 'InspectionQueries';
  scheduleCheckList: Array<ScheduleCheck>;
};


export type InspectionQueriesScheduleCheckListArgs = {
  checkListId: Scalars['String'];
};

export type IntOperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export type Inventory = {
  __typename?: 'Inventory';
  productId: Scalars['String'];
  productName: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  unitsOnOrder?: Maybe<Scalars['Int']>;
  reOrderLevel?: Maybe<Scalars['Int']>;
  modelNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  reOrder?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  searchMod?: Maybe<Scalars['String']>;
  searchMaker?: Maybe<Scalars['String']>;
  searchPart?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Decimal']>;
  powerRequired?: Maybe<Scalars['String']>;
  weightQty?: Maybe<Scalars['String']>;
  bonded?: Maybe<Scalars['Boolean']>;
  noView?: Maybe<Scalars['Boolean']>;
  fldConsumable?: Maybe<Scalars['Boolean']>;
  fldUseQty?: Maybe<Scalars['String']>;
  fldFactor?: Maybe<Scalars['Decimal']>;
  fldOrderQty?: Maybe<Scalars['String']>;
  fldOrderAmt?: Maybe<Scalars['Int']>;
  reOrderAmt?: Maybe<Scalars['Int']>;
  fldDataType?: Maybe<Scalars['String']>;
  fldRegion?: Maybe<Scalars['String']>;
  fldCountry?: Maybe<Scalars['String']>;
  fldSize?: Maybe<Scalars['String']>;
  fldColor?: Maybe<Scalars['String']>;
  fldLlbc?: Maybe<Scalars['String']>;
  fldPartNumber?: Maybe<Scalars['String']>;
  fldGuestType?: Maybe<Scalars['String']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldRx?: Maybe<Scalars['Boolean']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  invoices: Array<Invoice>;
  multipleLocations: Array<MultipleLocation>;
};

/** A segment of a collection. */
export type InventoryCollectionSegment = {
  __typename?: 'InventoryCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Inventory>>;
  totalCount: Scalars['Int'];
};

export type InventoryFilterInput = {
  and?: Maybe<Array<InventoryFilterInput>>;
  or?: Maybe<Array<InventoryFilterInput>>;
  productId?: Maybe<StringOperationFilterInput>;
  productName?: Maybe<StringOperationFilterInput>;
  productDescription?: Maybe<StringOperationFilterInput>;
  unitsOnOrder?: Maybe<IntOperationFilterInput>;
  reOrderLevel?: Maybe<IntOperationFilterInput>;
  modelNumber?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
  reOrder?: Maybe<BooleanOperationFilterInput>;
  manufacturer?: Maybe<StringOperationFilterInput>;
  qty?: Maybe<StringOperationFilterInput>;
  searchMod?: Maybe<StringOperationFilterInput>;
  searchMaker?: Maybe<StringOperationFilterInput>;
  searchPart?: Maybe<StringOperationFilterInput>;
  weight?: Maybe<DecimalOperationFilterInput>;
  powerRequired?: Maybe<StringOperationFilterInput>;
  weightQty?: Maybe<StringOperationFilterInput>;
  bonded?: Maybe<BooleanOperationFilterInput>;
  noView?: Maybe<BooleanOperationFilterInput>;
  fldConsumable?: Maybe<BooleanOperationFilterInput>;
  fldUseQty?: Maybe<StringOperationFilterInput>;
  fldFactor?: Maybe<DecimalOperationFilterInput>;
  fldOrderQty?: Maybe<StringOperationFilterInput>;
  fldOrderAmt?: Maybe<IntOperationFilterInput>;
  reOrderAmt?: Maybe<IntOperationFilterInput>;
  fldDataType?: Maybe<StringOperationFilterInput>;
  fldRegion?: Maybe<StringOperationFilterInput>;
  fldCountry?: Maybe<StringOperationFilterInput>;
  fldSize?: Maybe<StringOperationFilterInput>;
  fldColor?: Maybe<StringOperationFilterInput>;
  fldLlbc?: Maybe<StringOperationFilterInput>;
  fldPartNumber?: Maybe<StringOperationFilterInput>;
  fldGuestType?: Maybe<StringOperationFilterInput>;
  fldSms?: Maybe<BooleanOperationFilterInput>;
  dateEntered?: Maybe<DateTimeOperationFilterInput>;
  fldRx?: Maybe<BooleanOperationFilterInput>;
  fldSrhkey?: Maybe<StringOperationFilterInput>;
  deletedAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  isRecoverable?: Maybe<BooleanOperationFilterInput>;
  autoId?: Maybe<IntOperationFilterInput>;
  invoices?: Maybe<ListFilterInputTypeOfInvoiceFilterInput>;
  multipleLocations?: Maybe<ListFilterInputTypeOfMultipleLocationFilterInput>;
};

export type InventoryLocation = {
  __typename?: 'InventoryLocation';
  inventory: Inventory;
  amount?: Maybe<Scalars['Decimal']>;
  locationPath?: Maybe<Scalars['String']>;
};

export type InventoryLocationPayload = {
  __typename?: 'InventoryLocationPayload';
  inventoryLocations?: Maybe<Array<InventoryLocation>>;
  errors?: Maybe<Array<UserError>>;
};

export type InventorySortInput = {
  productId?: Maybe<SortEnumType>;
  productName?: Maybe<SortEnumType>;
  productDescription?: Maybe<SortEnumType>;
  unitsOnOrder?: Maybe<SortEnumType>;
  reOrderLevel?: Maybe<SortEnumType>;
  modelNumber?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  reOrder?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  qty?: Maybe<SortEnumType>;
  searchMod?: Maybe<SortEnumType>;
  searchMaker?: Maybe<SortEnumType>;
  searchPart?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  powerRequired?: Maybe<SortEnumType>;
  weightQty?: Maybe<SortEnumType>;
  bonded?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  fldConsumable?: Maybe<SortEnumType>;
  fldUseQty?: Maybe<SortEnumType>;
  fldFactor?: Maybe<SortEnumType>;
  fldOrderQty?: Maybe<SortEnumType>;
  fldOrderAmt?: Maybe<SortEnumType>;
  reOrderAmt?: Maybe<SortEnumType>;
  fldDataType?: Maybe<SortEnumType>;
  fldRegion?: Maybe<SortEnumType>;
  fldCountry?: Maybe<SortEnumType>;
  fldSize?: Maybe<SortEnumType>;
  fldColor?: Maybe<SortEnumType>;
  fldLlbc?: Maybe<SortEnumType>;
  fldPartNumber?: Maybe<SortEnumType>;
  fldGuestType?: Maybe<SortEnumType>;
  fldSms?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  fldRx?: Maybe<SortEnumType>;
  fldSrhkey?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type Invoice = {
  __typename?: 'Invoice';
  invoiceKey: Scalars['String'];
  productId: Scalars['String'];
  supplierName?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  book?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  figure?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  qty?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Decimal']>;
  currencyType?: Maybe<Scalars['String']>;
  currencyAmt?: Maybe<Scalars['Decimal']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  product: Inventory;
};

export type InvoiceFilterInput = {
  and?: Maybe<Array<InvoiceFilterInput>>;
  or?: Maybe<Array<InvoiceFilterInput>>;
  invoiceKey?: Maybe<StringOperationFilterInput>;
  productId?: Maybe<StringOperationFilterInput>;
  supplierName?: Maybe<StringOperationFilterInput>;
  contact?: Maybe<StringOperationFilterInput>;
  book?: Maybe<StringOperationFilterInput>;
  page?: Maybe<StringOperationFilterInput>;
  figure?: Maybe<StringOperationFilterInput>;
  amount?: Maybe<DecimalOperationFilterInput>;
  qty?: Maybe<StringOperationFilterInput>;
  orderDate?: Maybe<DateTimeOperationFilterInput>;
  orderId?: Maybe<StringOperationFilterInput>;
  invoiceNumber?: Maybe<StringOperationFilterInput>;
  unitPrice?: Maybe<DecimalOperationFilterInput>;
  currencyType?: Maybe<StringOperationFilterInput>;
  currencyAmt?: Maybe<DecimalOperationFilterInput>;
  dateEntered?: Maybe<DateTimeOperationFilterInput>;
  deletedAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  isRecoverable?: Maybe<BooleanOperationFilterInput>;
  autoId?: Maybe<IntOperationFilterInput>;
  product?: Maybe<InventoryFilterInput>;
};

export type ListFilterInputTypeOfInventoryFilterInput = {
  all?: Maybe<InventoryFilterInput>;
  none?: Maybe<InventoryFilterInput>;
  some?: Maybe<InventoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfInvoiceFilterInput = {
  all?: Maybe<InvoiceFilterInput>;
  none?: Maybe<InvoiceFilterInput>;
  some?: Maybe<InvoiceFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfMultipleLocationFilterInput = {
  all?: Maybe<MultipleLocationFilterInput>;
  none?: Maybe<MultipleLocationFilterInput>;
  some?: Maybe<MultipleLocationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

/** A segment of a collection. */
export type LogEntriesCollectionSegment = {
  __typename?: 'LogEntriesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<LogEntry>>;
  totalCount: Scalars['Int'];
};

export type LogEntry = {
  __typename?: 'LogEntry';
  pkey: Scalars['String'];
  description: Scalars['String'];
  logDate: Scalars['DateTime'];
  month: Scalars['String'];
  year: Scalars['String'];
  equipmentName?: Maybe<Scalars['String']>;
  dayLogKey?: Maybe<Scalars['String']>;
  maintKey?: Maybe<Scalars['String']>;
  eqKey?: Maybe<Scalars['String']>;
  jobNumber?: Maybe<Scalars['String']>;
  dayLogType?: Maybe<Scalars['Byte']>;
  fldCost?: Maybe<Scalars['Decimal']>;
  curr?: Maybe<Scalars['String']>;
  fldCompany?: Maybe<Scalars['String']>;
  fldTime?: Maybe<Scalars['Decimal']>;
  fldTimeQty?: Maybe<Scalars['String']>;
  fldHours?: Maybe<Scalars['Int']>;
  fldPerformedBy?: Maybe<Scalars['String']>;
  fldEnteredBy?: Maybe<Scalars['String']>;
  fldTsachk?: Maybe<Scalars['Boolean']>;
  fldGeoLocation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  fldLogEntryTime?: Maybe<Scalars['DateTime']>;
  fldCrewId?: Maybe<Scalars['String']>;
  fldNoView?: Maybe<Scalars['Boolean']>;
  fldRestricted?: Maybe<Scalars['Boolean']>;
  fldBillable?: Maybe<Scalars['Boolean']>;
  fldIsWarranty?: Maybe<Scalars['Boolean']>;
  fldLogEntryRtf?: Maybe<Scalars['String']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  location?: Maybe<TblLocationsHierarchy>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldRelEntry?: Maybe<Scalars['Boolean']>;
  fldIsCheckList?: Maybe<Scalars['Boolean']>;
  fldWorkList?: Maybe<Scalars['String']>;
  fldRelatedKey?: Maybe<Scalars['String']>;
  fldHtml?: Maybe<Scalars['String']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  category?: Maybe<TblSystemsHierarchy>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  checks?: Maybe<Scalars['String']>;
  equipment?: Maybe<Equipment>;
  photosCount?: Maybe<Scalars['Int']>;
  documentsCount?: Maybe<Scalars['Int']>;
  sparesUsedCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  attachments: Array<Attachment>;
};

export enum LogEntryFilterBy {
  Category = 'CATEGORY',
  Location = 'LOCATION'
}

export type LogEntryFilterInput = {
  logDateStart?: Maybe<Scalars['DateTime']>;
  logDateEnd?: Maybe<Scalars['DateTime']>;
  fldHtml?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  fldWorkList?: Maybe<Scalars['String']>;
  fldPerformedBy?: Maybe<Scalars['String']>;
  fldEnteredBy?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  fldRestricted?: Maybe<Scalars['Boolean']>;
  fldIsWarranty?: Maybe<Scalars['Boolean']>;
  eqKey?: Maybe<Scalars['String']>;
  maintKey?: Maybe<Scalars['String']>;
};

export type LogEntryInput = {
  pKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  eqKey?: Maybe<Scalars['String']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  fldWorkList?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  fldHours?: Maybe<Scalars['Int']>;
  fldEnteredBy?: Maybe<Scalars['String']>;
  fldPerformedBy?: Maybe<Scalars['String']>;
  fldCompany?: Maybe<Scalars['String']>;
  fldTime?: Maybe<Scalars['Decimal']>;
  fldTimeQty?: Maybe<Scalars['String']>;
  fldCost?: Maybe<Scalars['Decimal']>;
  curr?: Maybe<Scalars['String']>;
  fldIsWarranty?: Maybe<Scalars['Boolean']>;
  fldHtml?: Maybe<Scalars['String']>;
  fldCrewId?: Maybe<Scalars['String']>;
  fldRestricted?: Maybe<Scalars['Boolean']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  dayLogType?: Maybe<Scalars['Byte']>;
};

export type LogEntryPayload = {
  __typename?: 'LogEntryPayload';
  logEntry?: Maybe<LogEntry>;
  errors?: Maybe<Array<UserError>>;
};

export type LogEntrySortInput = {
  pkey?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  logDate?: Maybe<SortEnumType>;
  month?: Maybe<SortEnumType>;
  year?: Maybe<SortEnumType>;
  equipmentName?: Maybe<SortEnumType>;
  dayLogKey?: Maybe<SortEnumType>;
  maintKey?: Maybe<SortEnumType>;
  eqKey?: Maybe<SortEnumType>;
  jobNumber?: Maybe<SortEnumType>;
  dayLogType?: Maybe<SortEnumType>;
  fldCost?: Maybe<SortEnumType>;
  curr?: Maybe<SortEnumType>;
  fldCompany?: Maybe<SortEnumType>;
  fldTime?: Maybe<SortEnumType>;
  fldTimeQty?: Maybe<SortEnumType>;
  fldHours?: Maybe<SortEnumType>;
  fldPerformedBy?: Maybe<SortEnumType>;
  fldEnteredBy?: Maybe<SortEnumType>;
  fldTsachk?: Maybe<SortEnumType>;
  fldGeoLocation?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  fldLogEntryTime?: Maybe<SortEnumType>;
  fldCrewId?: Maybe<SortEnumType>;
  fldNoView?: Maybe<SortEnumType>;
  fldRestricted?: Maybe<SortEnumType>;
  fldBillable?: Maybe<SortEnumType>;
  fldIsWarranty?: Maybe<SortEnumType>;
  fldLogEntryRtf?: Maybe<SortEnumType>;
  fldLocHierarchy?: Maybe<SortEnumType>;
  location?: Maybe<TblLocationsHierarchySortInput>;
  dateEntered?: Maybe<SortEnumType>;
  fldRelEntry?: Maybe<SortEnumType>;
  fldIsCheckList?: Maybe<SortEnumType>;
  fldWorkList?: Maybe<SortEnumType>;
  fldRelatedKey?: Maybe<SortEnumType>;
  fldHtml?: Maybe<SortEnumType>;
  fldSrhkey?: Maybe<SortEnumType>;
  category?: Maybe<TblSystemsHierarchySortInput>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  fldSms?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
  checks?: Maybe<SortEnumType>;
  equipment?: Maybe<EquipmentSortInput>;
  photosCount?: Maybe<SortEnumType>;
  documentsCount?: Maybe<SortEnumType>;
  sparesUsedCount?: Maybe<SortEnumType>;
  commentsCount?: Maybe<SortEnumType>;
};

export type ModifyCommentInput = {
  id: Scalars['String'];
  content: Scalars['String'];
};

export type ModifyLogEntriesPayload = {
  __typename?: 'ModifyLogEntriesPayload';
  logEntries?: Maybe<Array<LogEntry>>;
  errors?: Maybe<Array<UserError>>;
};

export type MultipleLocation = {
  __typename?: 'MultipleLocation';
  pkey: Scalars['String'];
  productId: Scalars['String'];
  serialNum?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  useBy?: Maybe<Scalars['DateTime']>;
  fldDefault?: Maybe<Scalars['Boolean']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldMaxHldgLoc?: Maybe<Scalars['Decimal']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  product: Inventory;
  location?: Maybe<TblLocationsHierarchy>;
};

export type MultipleLocationFilterInput = {
  and?: Maybe<Array<MultipleLocationFilterInput>>;
  or?: Maybe<Array<MultipleLocationFilterInput>>;
  pkey?: Maybe<StringOperationFilterInput>;
  productId?: Maybe<StringOperationFilterInput>;
  serialNum?: Maybe<StringOperationFilterInput>;
  amount?: Maybe<DecimalOperationFilterInput>;
  useBy?: Maybe<DateTimeOperationFilterInput>;
  fldDefault?: Maybe<BooleanOperationFilterInput>;
  fldLocHierarchy?: Maybe<StringOperationFilterInput>;
  dateEntered?: Maybe<DateTimeOperationFilterInput>;
  fldMaxHldgLoc?: Maybe<DecimalOperationFilterInput>;
  deletedAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  isRecoverable?: Maybe<BooleanOperationFilterInput>;
  autoId?: Maybe<IntOperationFilterInput>;
  product?: Maybe<InventoryFilterInput>;
  location?: Maybe<TblLocationsHierarchyFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addLogEntry: LogEntryPayload;
  upsertLogEntry?: Maybe<LogEntryPayload>;
  modifyLogEntry?: Maybe<LogEntryPayload>;
  deleteLogEntry?: Maybe<DeleteLogEntryPayload>;
  deleteLogEntries?: Maybe<ModifyLogEntriesPayload>;
  addSpare: SparePayload;
  modifySpare: SparePayload;
  upsertSpare: SparePayload;
  deleteSpare: SparePayload;
  addComment: CommentPayload;
  modifyComment: CommentPayload;
  deleteComment: CommentPayload;
  addAttachment: AttachmentPayload;
  modifyAttachment: AttachmentPayload;
  upsertAttachment: AttachmentPayload;
  deleteAttachmentFromRecord: AttachmentPayload;
  upsertGridSavedView: GridSavedViewPayload;
  createGridSavedView: GridSavedViewPayload;
  modifyGridSavedView: GridSavedViewPayload;
  deleteGridSavedView: GridSavedViewPayload;
};


export type MutationAddLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationUpsertLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationModifyLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationDeleteLogEntryArgs = {
  logEntryId: Scalars['String'];
};


export type MutationDeleteLogEntriesArgs = {
  logEntryIds: Array<Scalars['String']>;
};


export type MutationAddSpareArgs = {
  input: SparesUsedInput;
};


export type MutationModifySpareArgs = {
  input: SparesUsedInput;
};


export type MutationUpsertSpareArgs = {
  input: SparesUsedInput;
};


export type MutationDeleteSpareArgs = {
  spareId: Scalars['String'];
};


export type MutationAddCommentArgs = {
  input: AddCommentInput;
};


export type MutationModifyCommentArgs = {
  input: ModifyCommentInput;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String'];
};


export type MutationAddAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationModifyAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationUpsertAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationDeleteAttachmentFromRecordArgs = {
  input: AttachmentCrossReferenceInput;
};


export type MutationUpsertGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationCreateGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationModifyGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationDeleteGridSavedViewArgs = {
  gridSavedViewId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  logEntries?: Maybe<LogEntriesCollectionSegment>;
  logEntry?: Maybe<LogEntry>;
  logEntriesGroupedByEquipment?: Maybe<Array<GroupListOfEquipmentAndLogEntry>>;
  logEntryCountsByCategory: Array<GroupCountOfTblSystemsHierarchy>;
  logEntryCountsByLocation: Array<GroupCountOfTblLocationsHierarchy>;
  categories: Array<TblSystemsHierarchy>;
  locations: Array<TblLocationsHierarchy>;
  equipmentPaged?: Maybe<EquipmentPagedCollectionSegment>;
  equipment: Array<Equipment>;
  equipmentById?: Maybe<Equipment>;
  inventory?: Maybe<InventoryCollectionSegment>;
  inventoryForEquipment: Array<Inventory>;
  inventoryById?: Maybe<Inventory>;
  inventoryLocations: InventoryLocationPayload;
  commentsForRecord: Array<Comment>;
  list: Array<TblDdlistDefault>;
  crewNames: Array<Crew>;
  currentUser: Crew;
  currencies: Array<Currency>;
  companies: Array<Company>;
  companyNames: Array<CompanyName>;
  sparesUsed: Array<TblSparesUsed>;
  attachmentsForRecords: Array<Attachment>;
  attachmentsForRecord: Array<Attachment>;
  attachmentById?: Maybe<Attachment>;
  gridSavedViews: Array<TblGridSavedView>;
  documentation?: Maybe<DocumentationCollectionSegment>;
};


export type QueryLogEntriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<LogEntryFilterInput>;
  order?: Maybe<Array<LogEntrySortInput>>;
};


export type QueryLogEntryArgs = {
  pKey: Scalars['String'];
};


export type QueryLogEntriesGroupedByEquipmentArgs = {
  filterById?: Maybe<Scalars['String']>;
  filterBy: LogEntryFilterBy;
  filterInput: LogEntryFilterInput;
};


export type QueryLogEntryCountsByCategoryArgs = {
  filterInput?: Maybe<LogEntryFilterInput>;
};


export type QueryLogEntryCountsByLocationArgs = {
  filterInput?: Maybe<LogEntryFilterInput>;
};


export type QueryEquipmentPagedArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EquipmentFilterInput>;
  order?: Maybe<Array<EquipmentSortInput>>;
};


export type QueryEquipmentArgs = {
  where?: Maybe<EquipmentFilterInput>;
  order?: Maybe<Array<EquipmentSortInput>>;
};


export type QueryEquipmentByIdArgs = {
  eqKey: Scalars['String'];
};


export type QueryInventoryArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InventoryFilterInput>;
  order?: Maybe<Array<InventorySortInput>>;
};


export type QueryInventoryForEquipmentArgs = {
  equipmentId: Scalars['String'];
};


export type QueryInventoryByIdArgs = {
  productId: Scalars['String'];
};


export type QueryInventoryLocationsArgs = {
  productId: Scalars['String'];
};


export type QueryCommentsForRecordArgs = {
  recordId: Scalars['String'];
  order?: Maybe<Array<CommentSortInput>>;
};


export type QueryListArgs = {
  listName: Scalars['String'];
};


export type QueryCrewNamesArgs = {
  order?: Maybe<Array<CrewSortInput>>;
};


export type QueryCurrenciesArgs = {
  order?: Maybe<Array<CurrencySortInput>>;
};


export type QueryCompaniesArgs = {
  where?: Maybe<CompanyFilterInput>;
  order?: Maybe<Array<CompanySortInput>>;
};


export type QuerySparesUsedArgs = {
  logKey: Scalars['String'];
};


export type QueryAttachmentsForRecordsArgs = {
  recordIds: Array<Scalars['String']>;
  attachmentType: AttachmentType;
  order?: Maybe<Array<AttachmentSortInput>>;
};


export type QueryAttachmentsForRecordArgs = {
  recordId: Scalars['String'];
  recordType: RecordType;
  attachmentType: AttachmentType;
};


export type QueryAttachmentByIdArgs = {
  attachmentId: Scalars['String'];
};


export type QueryGridSavedViewsArgs = {
  moduleName: Scalars['String'];
};


export type QueryDocumentationArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<DocumentationFilterInput>;
  order?: Maybe<Array<DocumentationDtoSortInput>>;
};

export enum RecordType {
  LogEntry = 'LOG_ENTRY',
  Equipment = 'EQUIPMENT',
  WorkIssue = 'WORK_ISSUE',
  InventoryGeneral = 'INVENTORY_GENERAL',
  InventoryBeverages = 'INVENTORY_BEVERAGES',
  InventoryUniform = 'INVENTORY_UNIFORM',
  InventoryDryAndColdStore = 'INVENTORY_DRY_AND_COLD_STORE',
  InventoryMedical = 'INVENTORY_MEDICAL',
  EquipmentSchedule = 'EQUIPMENT_SCHEDULE',
  EventSchedule = 'EVENT_SCHEDULE',
  DrillSchedule = 'DRILL_SCHEDULE',
  InspectionSchedule = 'INSPECTION_SCHEDULE',
  PurchaseOrder = 'PURCHASE_ORDER',
  EngineersDayLog = 'ENGINEERS_DAY_LOG',
  RunningLog = 'RUNNING_LOG',
  Crew = 'CREW',
  Guest = 'GUEST',
  SmsReport = 'SMS_REPORT',
  IspsReport = 'ISPS_REPORT',
  VesselCertificates = 'VESSEL_CERTIFICATES',
  CrewCertificates = 'CREW_CERTIFICATES',
  DocumentationVesselReference = 'DOCUMENTATION_VESSEL_REFERENCE',
  DocumentationDrawings = 'DOCUMENTATION_DRAWINGS',
  DocumentationManuals = 'DOCUMENTATION_MANUALS',
  DocumentationMsds = 'DOCUMENTATION_MSDS',
  DocumentationPhotos = 'DOCUMENTATION_PHOTOS',
  DocumentationSms = 'DOCUMENTATION_SMS',
  DocumentationIsps = 'DOCUMENTATION_ISPS',
  HoursOfRest = 'HOURS_OF_REST',
  Undefined = 'UNDEFINED'
}

export type ScheduleCheck = {
  __typename?: 'ScheduleCheck';
  pkey: Scalars['String'];
  fkey?: Maybe<Scalars['String']>;
  eqKey?: Maybe<Scalars['String']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fldTitle?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  fldIndex?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SparePayload = {
  __typename?: 'SparePayload';
  spare?: Maybe<TblSparesUsed>;
  errors?: Maybe<Array<UserError>>;
};

export type SparesUsedInput = {
  pkey?: Maybe<Scalars['String']>;
  logKey: Scalars['String'];
  productId: Scalars['String'];
  amount: Scalars['Int'];
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export type TblAnyReport = {
  __typename?: 'TblAnyReport';
  pkey: Scalars['String'];
  fldReportType: Scalars['String'];
  fldReportFile: Scalars['String'];
  fldReportPassword?: Maybe<Scalars['String']>;
  fldDateSent?: Maybe<Scalars['DateTime']>;
  fldDateCreated?: Maybe<Scalars['DateTime']>;
  fldDateReceived?: Maybe<Scalars['DateTime']>;
  fldCreatedBy?: Maybe<Scalars['String']>;
  fldCreatedByName: Scalars['String'];
  fldParent?: Maybe<Scalars['String']>;
  fldSend?: Maybe<Scalars['Boolean']>;
  fldNoView?: Maybe<Scalars['Boolean']>;
  fldReportNumber?: Maybe<Scalars['String']>;
  fldRemark?: Maybe<Scalars['String']>;
  fldVerifiedBy?: Maybe<Scalars['String']>;
  fldCompletedBy?: Maybe<Scalars['String']>;
  fldVerifiedDate?: Maybe<Scalars['DateTime']>;
  fldCompletedDate?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  comments: Array<Comment>;
  tblAnyReportAccessLogs: Array<TblAnyReportAccessLog>;
  tblAnyReportHistories: Array<TblAnyReportHistory>;
};

export type TblAnyReportAccessLog = {
  __typename?: 'TblAnyReportAccessLog';
  pkey: Scalars['String'];
  parentKey: Scalars['String'];
  instanceKey: Scalars['String'];
  fldLastAccessedDate?: Maybe<Scalars['DateTime']>;
  fldLastAccessedBy?: Maybe<Scalars['String']>;
  fldLastAccessedByName: Scalars['String'];
  fldAction?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  parentKeyNavigation: TblAnyReport;
};

export type TblAnyReportHistory = {
  __typename?: 'TblAnyReportHistory';
  pkey: Scalars['String'];
  fkey: Scalars['String'];
  fldReportFile: Scalars['String'];
  fldReportPassword?: Maybe<Scalars['String']>;
  fldLastAccessedDate?: Maybe<Scalars['DateTime']>;
  fldLastAccessedBy?: Maybe<Scalars['String']>;
  fldLastAccessedByName: Scalars['String'];
  fldDateRevision?: Maybe<Scalars['DateTime']>;
  fldAction?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  fkeyNavigation: TblAnyReport;
};

export type TblAnyReportSortInput = {
  pkey?: Maybe<SortEnumType>;
  fldReportType?: Maybe<SortEnumType>;
  fldReportFile?: Maybe<SortEnumType>;
  fldReportPassword?: Maybe<SortEnumType>;
  fldDateSent?: Maybe<SortEnumType>;
  fldDateCreated?: Maybe<SortEnumType>;
  fldDateReceived?: Maybe<SortEnumType>;
  fldCreatedBy?: Maybe<SortEnumType>;
  fldCreatedByName?: Maybe<SortEnumType>;
  fldParent?: Maybe<SortEnumType>;
  fldSend?: Maybe<SortEnumType>;
  fldNoView?: Maybe<SortEnumType>;
  fldReportNumber?: Maybe<SortEnumType>;
  fldRemark?: Maybe<SortEnumType>;
  fldVerifiedBy?: Maybe<SortEnumType>;
  fldCompletedBy?: Maybe<SortEnumType>;
  fldVerifiedDate?: Maybe<SortEnumType>;
  fldCompletedDate?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type TblDdlistDefault = {
  __typename?: 'TblDdlistDefault';
  pkey: Scalars['String'];
  fldListName?: Maybe<Scalars['String']>;
  fldMember?: Maybe<Scalars['String']>;
  fldT4reqd: Scalars['Boolean'];
  fldIndex?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblGridSavedView = {
  __typename?: 'TblGridSavedView';
  pkey: Scalars['String'];
  fldCrewId?: Maybe<Scalars['String']>;
  fldLayout?: Maybe<Scalars['String']>;
  fldModule?: Maybe<Scalars['String']>;
  fldViewName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblLocationsHierarchy = {
  __typename?: 'TblLocationsHierarchy';
  pkey: Scalars['String'];
  fldLocation: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  fldTags?: Maybe<Scalars['String']>;
  fldDepts?: Maybe<Scalars['String']>;
  fldNoView?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblLocationsHierarchyFilterInput = {
  and?: Maybe<Array<TblLocationsHierarchyFilterInput>>;
  or?: Maybe<Array<TblLocationsHierarchyFilterInput>>;
  pkey?: Maybe<StringOperationFilterInput>;
  fldLocation?: Maybe<StringOperationFilterInput>;
  parentId?: Maybe<StringOperationFilterInput>;
  fldTags?: Maybe<StringOperationFilterInput>;
  fldDepts?: Maybe<StringOperationFilterInput>;
  fldNoView?: Maybe<BooleanOperationFilterInput>;
  deletedAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  isRecoverable?: Maybe<BooleanOperationFilterInput>;
  autoId?: Maybe<IntOperationFilterInput>;
};

export type TblLocationsHierarchySortInput = {
  pkey?: Maybe<SortEnumType>;
  fldLocation?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  fldTags?: Maybe<SortEnumType>;
  fldDepts?: Maybe<SortEnumType>;
  fldNoView?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type TblSparesUsed = {
  __typename?: 'TblSparesUsed';
  pkey: Scalars['String'];
  productId: Scalars['String'];
  logKey?: Maybe<Scalars['String']>;
  eqKey?: Maybe<Scalars['String']>;
  workKey?: Maybe<Scalars['String']>;
  multiKey?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  fldDateUsed?: Maybe<Scalars['DateTime']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldUpdateInvOnSave?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  inventory: Inventory;
};

export type TblSystemsHierarchy = {
  __typename?: 'TblSystemsHierarchy';
  pkey: Scalars['String'];
  fldCategory: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  fldTags?: Maybe<Scalars['String']>;
  fldDepts?: Maybe<Scalars['String']>;
  fldNoView?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblSystemsHierarchySortInput = {
  pkey?: Maybe<SortEnumType>;
  fldCategory?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  fldTags?: Maybe<SortEnumType>;
  fldDepts?: Maybe<SortEnumType>;
  fldNoView?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type UserError = {
  __typename?: 'UserError';
  message: Scalars['String'];
  code: Scalars['String'];
};
