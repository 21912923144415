import { FC, useState } from 'react';
import { isNil, isArray, last, isString, isEmpty } from 'lodash';
import { Button, DialogTitle, DialogActions, DialogContent, Box, IconButton, Snackbar, Alert } from '@mui/material';
import { DeleteTwoTone } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import { LoadingButton } from '@mui/lab';
import { getDatabase } from '../../rxdb';
import LogEntryWithWorkIssueDetailsForm from '../dataentry/logentryDE/LogEntryWithWorkIssueDetailsForm';
import { useAuth } from '../../contexts/auth';
import { getPlainTextFromHtml } from '../../utils';
import { TblSparesUsedDocument } from '../SparesTab/rxdb';
import WarningDialog from 'src/components/UI/WarningDialog';
import { normalizeDateTime } from 'src/helpers';
import LogEntryCheckDetailForm from '../dataentry/logentryDE/LogEntryCheckDetailForm';
import LogEntryEquipmentHoursDialog from '../dataentry/logentryDE/component/LogEntryEquipmentHoursDialog';
import { CustomDialog } from 'src/helpers/modals';
import { updateSpares } from '../dataentry/logentryDE/utilsOld';

interface InjectedProps {
  visible: boolean;
  onSave?: (logEntry: LogEntryDocument, isCreate: boolean) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  logEntry?: LogEntryDocument;
  issue?: WorkIssuesDocument;
  onPreSave?: () => Promise<boolean>;
}

const LogEntryFormDialog: FC<InjectedProps> = ({
  issue,
  logEntry,
  visible,
  onCancel,
  onSave,
  onDelete,
  onPreSave,
}) => {
  
  const { user } = useAuth();
  const [processing, setProcessing] = useState<boolean>(false);
  const isCreation = isNil(logEntry?.PKey);
  const [isDeleting, setIsDeleting] = useState(false);
  const [eqWarningDialog, setEqWarningDialog] = useState(false);
  const [saveData, setSaveData] = useState<any>();
  const [savedSpares, setSavedSpares] = useState<TblSparesUsedDocument[]>([]);
  const [eqHours, setEqHours] = useState<number>(0);
  const [logEqHours, setLogEqHours] = useState<number>(0);

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const onChangeHours = async (value:any)=>{
    if(parseInt(value)>0){
      setLogEqHours(parseInt(value))
      
    }
  }

  const onSaveClick = async (data: any, spares: TblSparesUsedDocument[])=>{
    setProcessing(true);
   
    const db = await getDatabase();
    if(data.EqKey){
      const equipmentData = await db.equipment.find({
        selector:{
          EqKey: {$eq: data.EqKey }
        }
      }).exec()
      
      if(equipmentData[0] && equipmentData[0].fldCountHours && isEmpty(data.fldHours) ){
 
        setEqHours(equipmentData[0].Hours || 0)
        setSaveData(data)
        setSavedSpares(spares)
        setEqWarningDialog(true)
      } else {
        onSubmit(data,spares)
      }
    } else {
      onSubmit(data,spares)
    }
  }

  const onContinueAnyWay = async()=>{
      onSubmit(saveData,savedSpares)
    setEqWarningDialog(false)
  }

  const onEnterEqHours = async()=>{
    setProcessing(false);
    setSaveData(null)
    setSavedSpares([])
    setEqWarningDialog(false)
  }

  const getEquipmentValue = (item: any) => {
    switch (typeof item) {
      case 'string':
        return item;
      case 'object':
        return item?.UniqueName
      default:
        return null
    }
  }

  const onSubmit = async (data: any, spares: TblSparesUsedDocument[]) => {
    setProcessing(true);
    const db = await getDatabase();

    const {
      fldCompany,
      LogDate,
      fldCost,
      fldEnteredBy,
      fldHours,
      fldIsWarranty,
      fldPerformedBy,
      fldRestricted,
      fldSMS,
      fldTime,
      fldTimeQty,
      fldWorkList,
      Department,
      Curr,
      EqKey,
      fldSRHKey,
      fldLocHierarchy,
      LogEntry,
      fldHTML,
      
    } = data;

    if (isNil(logEntry)) {
      throw new Error('Log entry missing');
    }

    if (isNil(issue)) {
      throw new Error('Workissue missing');
    }

    // We will run some validation checks here.
    if (onPreSave) {
      await onPreSave();
    }

    try {
      const created = await db.logentry.upsert({
        EqKey: issue.EqKey || null,
        Equipment: getEquipmentValue(data.Equipment),
        MaintKey: issue.fldSchedMaintKey,
        DayLogType: 2,
        Curr: Curr?.Curr || issue.fldActualCurrency,
        fldCrewID: user?.fldCrewID || null,
        fldNoView: false,
        fldLocHierarchy: isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy,
        fldSRHKey: isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey,
        fldCompany: fldCompany?.DisplayMember || fldCompany || null,
        fldWorkList: isString(fldWorkList) ? fldWorkList : fldWorkList?.fldMember || null ,
        Department: isString(Department) ? Department : Department?.fldMember || null,
        fldTimeQty,
        LogDate: normalizeDateTime(LogDate),
        fldCost: parseInt(fldCost) || 0,
        fldEnteredBy,
        fldHTML,
        LogEntry: getPlainTextFromHtml(fldHTML),
        fldHours: fldHours ? parseInt(fldHours) : logEqHours || 0,
        fldIsWarranty,
        fldPerformedBy: fldPerformedBy?.join(', ') || null,
        fldRestricted,
        fldSMS: issue.fldSMS,
        DateEntered: new Date().toISOString(),
        JobNumber: issue.JobNumber,
        fldTime: parseInt(fldTime) || 0,
        PKey: logEntry.PKey || uuid(),
        Checks: issue.Checks || null,
      });

      const attachmentsUpdate = await db.tbldocumentcrossreference
      .find({
        selector: {
          fldRecordKey: { $eq: issue.JobNumber },
          deletedAt: {
            $eq: null
          },
        },
      }).exec()

      if (attachmentsUpdate.length > 0) {
        await Promise.all(attachmentsUpdate.map(async (obj) => {
          await db.tbldocumentcrossreference.atomicUpsert({
            ...obj.toJSON(),
            fldRecordKey: logEntry.PKey,
            fldTableName: 'LogEntry',
          })
        })
        )
      }

      // TODO: Update spares
      // Set LogKey for spares that came from Task or that we've adjusted.
      await updateSpares(spares, db, created);

      console.log("[LogEntry] ---- created  --- 1")
      onSave && (await onSave(created, !!logEntry.PKey));
      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Success!',
      });
    } catch (e: any) {
      console.log("error is detected", e)
      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
  };

  const handleDeleteOk = async () =>{
    try {
      await logEntry?.remove();
      handleCancel();
      onDelete && onDelete();

      setSnackbar({
        open: true,
        type: 'success',
        message: 'Success!',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: 'An error has occurred!',
      });
    }
    setIsDeleting(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  }

  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
    setSnackbar({
      open: false,
      type: 'success',
      message: '',
    });
  };

  const formName = issue?.fldSMSType === 'CHKL'? 'logentry-details-form': 'logentry-details-form-workissue'

  return (
    <>
      <CustomDialog 
        scroll="paper" 
        fullWidth 
        maxWidth="md" 
        open={visible}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Updating Log Entry</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
        {!isNil(logEntry) && !isNil(issue) && (
          issue.fldSMSType === 'CHKL' ? (
            <LogEntryCheckDetailForm
              logEntry={logEntry}
              workIssue={issue}
              onSubmit={onSaveClick}
            />
          ) : (
            <LogEntryWithWorkIssueDetailsForm
              logEntry={logEntry}
              workIssue={issue}
              onSubmit={onSaveClick}
            />
          )
        )}
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          {!isCreation && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete task"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button onClick={handleCancel} className="mr-2">
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              form={formName}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      {/* <WarningDialog
        visible={eqWarningDialog}
        title="Empty equipment hours"
        content="This Equipment monitor hours, you have not enter any hours for this equipment. Please selecte option to continue."
        okText='Enter Equipment Hours'
        cancelText='Continue anyway'
        color='warning'
        onOk={onEnterEqHours}
        onCancel={onContinueAnyWay}
      /> */}

      <LogEntryEquipmentHoursDialog
        visible={eqWarningDialog}
        title="Empty equipment hours"
        okText='Cancel'
        cancelText='Continue'
        color='warning'
        onOk={onEnterEqHours}
        onCancel={onContinueAnyWay}
        equipmentHours={eqHours}
        onChange={onChangeHours}
        fldHours={logEqHours}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LogEntryFormDialog;
