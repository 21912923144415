import { FC } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, FormGroup } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import DocumentRevision from 'src/modules/DocumentRevision';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import { useAppState } from 'src/contexts/app-state';
import CategoryDropdown from 'src/components/Dropdowns/CategoryDropdown';
import { LIBRARY } from 'src/consts';

interface DocItemSummaryProps {
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  libraryType? : string;
  readOnly: boolean;
  isCreate?: boolean;
}

const DocSummaryForm: FC<DocItemSummaryProps> = ({
  onChange,
  control,
  libraryType,
  readOnly,
  isCreate
}) => {
  const { settingsPersonal } = useAppState();
  const { path } = useParams() as { path: string };

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">
            <div className="mt-3">
              <CategoryDropdown
                name="fldSrhkey"
                label="Category"
                control={control}
                onChange={onChange}
                sourceForm={libraryType}
                rules={{ required: true }}
                allDepts={settingsPersonal?.fldAllDepts > 0}
              />
            </div>

            { !(path === LIBRARY.DRAWINGS.PATH || path === LIBRARY.PHOTOS.PATH || path === LIBRARY.VESSEL_REFERENCE.PATH) &&
              <FormGroup row className="mt-4">
                <div className="ml-5 w-40">
                  <Checkbox
                    control={control}
                    name="smsReport"
                    label="This is a report"
                  />
                </div>

                <div className="ml-5 w-40">
                  <Checkbox
                    control={control}
                    name="verificationReq"
                    label="Requires Verification"
                  />
                </div>
              </FormGroup>
            }
          </div>

          <div className="my-4">
            <RichTextEditor
              control={control}
              name="description"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="planning-status"
          id="planning-status"
        >
          Revision History
        </AccordionSummary>
        <AccordionDetails>
          {/* <DocumentRevision
            type={libraryType === 'PHOTOS' ? 'photo' : 'attachments'}
            allowedFileExtensions={libraryType === 'PHOTOS' ? ['.jpg', '.jpeg', '.gif', '.png'] : ['.pdf']}
            relatedKey={form.PKey}
            disabled={disabled}
          /> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DocSummaryForm;
