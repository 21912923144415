import { ApolloError, DocumentNode, gql, useMutation } from '@apollo/client';
import { LogEntry, RecordType, SparesUsedInput, TblSparesUsed } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_SPARES_USED } from './useGetSparesUsed';
import { useState } from 'react';
import { UpdateLogEntrySparesUsedCountCache } from '../fragments.graphql';

export const UPSERT_SPARE = gql`
  mutation UpsertSpare($input: SparesUsedInput!) {
    upsertSpare(input: $input) {
      spare {
        pkey
        amount
        productId
        inventory {
          productId
          productName
          manufacturer
          modelNumber
        }
      }
    }
  }
`;

export interface SpareUsedMutationResponse {
  responseData?: TblSparesUsed;
  responseMessage: string;
}

interface UpsertSpareResult {
  upsertSpare: (input: SparesUsedInput, isCreate: boolean) => Promise<SpareUsedMutationResponse>;
  upsertSpareLoading: boolean;
  upsertSpareError?: ApolloError;
}

export const useUpsertSpare = (recordId: string, recordType: RecordType, recordTypeName: string): UpsertSpareResult => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [upsertSpareUsed, { loading, error }] = useMutation(UPSERT_SPARE, {
    refetchQueries: [
      GET_SPARES_USED,
    ],
    onError: (error: ApolloError) => {
      logger('UpsertSpare').error(`Error ${isCreate ? 'adding' : 'updating'} spare for log entry: ${recordId} -->`, error.message);
    },
    update: (cache, { data }) => {
      if (isCreate) { // if is update we dont modify the spareCount value in the cache
        if (data?.upsertSpare?.spare) {

          const recordCacheId = cache.identify({
            __typename: recordTypeName,
            pkey: recordId,
          });

          let fragmentSelection: DocumentNode | null = null;

          switch (recordType) {
            case RecordType.LogEntry:
              fragmentSelection = UpdateLogEntrySparesUsedCountCache;
              break;
              
            case RecordType.WorkIssue:
            case RecordType.Equipment:
              break;
          
            case RecordType.InventoryGeneral:
            case RecordType.InventoryBeverages:
            case RecordType.InventoryUniform:
            case RecordType.InventoryDryAndColdStore:
            case RecordType.InventoryMedical:
              // Add your logic for handling different Inventory cases
              break;
          
            case RecordType.EquipmentSchedule:
            case RecordType.EventSchedule:
            case RecordType.DrillSchedule:
            case RecordType.InspectionSchedule:
              // Add your logic for handling different schedule-related cases
              break;
          
            case RecordType.PurchaseOrder:
              // Logic for Purchase Order case
              break;
          
            case RecordType.EngineersDayLog:
            case RecordType.RunningLog:
              // Logic for handling Engineer logs
              break;
          
            case RecordType.Crew:
            case RecordType.Guest:
              // Logic for handling Crew and Guest cases
              break;
          
            case RecordType.SmsReport:
            case RecordType.IspsReport:
              // Logic for handling report-related cases
              break;
          
            case RecordType.VesselCertificates:
            case RecordType.CrewCertificates:
              // Logic for handling certificate-related cases
              break;
          
            case RecordType.DocumentationVesselReference:
            case RecordType.DocumentationDrawings:
            case RecordType.DocumentationManuals:
            case RecordType.DocumentationMsds:
            case RecordType.DocumentationPhotos:
            case RecordType.DocumentationSms:
            case RecordType.DocumentationIsps:
              // Logic for handling documentation cases
              break;
          
            case RecordType.HoursOfRest:
              // Logic for handling HoursOfRest case
              break;
          
            case RecordType.Undefined:
              // Logic for handling undefined cases
              break;
          
            default:
              break;
          }

          if (fragmentSelection) {
  
            const existingRecord = cache.readFragment<any>({
              id: recordCacheId,
              fragment: fragmentSelection,
            });
  
            if (existingRecord) {
              cache.writeFragment({
                id: recordCacheId,
                fragment: fragmentSelection,
                data: {
                  sparesUsedCount: existingRecord.sparesUsedCount + 1,
                },
              });
            } else {
              logger('Cache-UpsertSpare').warning(`${recordType}: ${recordId} not found in cache`);
            }
          } else {
            logger('Cache-UpsertSpare').warning(`Query fragment not valid --> ${fragmentSelection}`);
          }
        } else {
          logger('Cache-UpsertSpare').warning(`${recordType}: ${recordId} cache update paused --> No response from upsert mutation`);
        }
      }
    },
  });

  const upsertSpare = async (input: SparesUsedInput, isCreate: boolean): Promise<SpareUsedMutationResponse> => {
    setIsCreate(isCreate);
    const response = await upsertSpareUsed({ variables: { input } });
    const responseData = response.data?.upsertSpare?.spare;
    if (responseData) {
      logger('UpsertSpare').info(`Spare for ${recordType} ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Spare ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} spare!`,
      };
    };
  };

  return { 
    upsertSpare, 
    upsertSpareLoading: loading, 
    upsertSpareError: error,
  };
};