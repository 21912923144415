import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { CollectionSegmentInfo, QueryLogEntriesArgs, Documentation, DocumentationFilterInput, DocumentationDtoSortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_DOCUMENTATION = gql`
  query GetDocumentation(
    $skip: Int
    $take: Int
    $filterInput: DocumentationFilterInput
    $order: [DocumentationDTOSortInput!]
  ) {
    documentation(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        addedBy
        autoId
        categoryId
        completeReqd
        dateEntered
        deletedAt
        deletedBy
        description
        docFigure
        docPage
        docTitle
        fileType
        id
        isRecoverable
        libType
        number
        password
        received
        showInCentral
        smsReport
        specRef
        status
        statusInternal
        toc
        updatedAt
        verifiedReq
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export interface DocumentationResult {
  data: Documentation[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number, filterInput: DocumentationFilterInput, order: DocumentationDtoSortInput[]) => void;
}

export const useGetDocumentation = ({ skip, take, filterInput, order }: QueryLogEntriesArgs): DocumentationResult => {
  const { data, loading, error, refetch } = useQuery(GET_DOCUMENTATION, {
    // fetchPolicy: "no-cache",
    variables: { skip, take, filterInput, order },
    notifyOnNetworkStatusChange: true,
  });

  const loadData = ( skip: number, take: number, filterInput: DocumentationFilterInput, order: DocumentationDtoSortInput[]) => {
    console.log('Loading data with:', { skip, take, filterInput, order });
    logger('GetDocumentation').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.documentation) {
          const { items, pageInfo } = result.data.documentation;
          logger('GetDocumentation').info('Documentation fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetDocumentation').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetDocumentation').error('Error re-fetching documentation -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetDocumentation').error('Error fetching documentation -->', error.message);
  }

  return {
    data: loading ? [] : data?.documentation?.items || [],
    totalCount: data?.documentation?.totalCount || 0,
    currentCount: data?.documentation?.items?.length || 0,
    pageInfo: data?.documentation?.pageInfo,
    loading,
    loadData,
  };
};
