import './styles.css';
import { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { Alert, Snackbar, Button, AlertColor } from '@mui/material';
import { isNotNil } from "src/utils";
import AddIcon from '@mui/icons-material/Add';
import AttachmentGrid from './component/AttachmentGrid';
import { Attachment, AttachmentCrossReferenceInput, AttachmentInput, RecordType, AttachmentType } from 'src/generated/dotnet.graphql';
import AttachmentPopup from './component/AttachmentPopup';
import { useGetAttachments } from 'src/hooks/attachments/useGetAttachments';
import { useDeleteAttachment } from 'src/hooks/attachments/useDeleteAttachment';
import { useUpsertAttachment } from 'src/hooks/attachments/useUpsertAttachment';
import { SEVERITY } from 'src/consts';

interface Props {
  recordId: string;
  recordType: RecordType;
  recordTypeName: string;
  attachmentType: AttachmentType;
  fldSrhkey?: string | null;
  setAttachmentsCount: (value: number) => void;
  readOnly?: boolean;
  disableCheckbox?: boolean;
}

interface IFile {
  contentType: string;
  extension: string;
  key: string;
  name: string;
  url: string;
}

const defaultFile = {
  contentType: '',
  extension: '',
  key: '',
  name: '',
  url: '',
};

const AttachmentTab = ({
  recordId,
  recordType,
  recordTypeName,
  attachmentType,
  fldSrhkey,
  setAttachmentsCount,
  readOnly = false,
  disableCheckbox = false,
}: Props) => {
  const { attachmentsData, attachmentsLoading, attachmentsCount } = useGetAttachments(recordId, recordType, attachmentType);
  const { deleteAttachment } = useDeleteAttachment(recordId, recordType, recordTypeName, attachmentType);
  const { upsertAttachment, upsertAttachmentLoading } = useUpsertAttachment(recordId, recordType, recordTypeName, attachmentType);

  const [uploadedFile, setUploadedFile] = useState<IFile>(defaultFile);
  const [reloadPhotos, setReloadPhotos] =useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<Attachment>();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  useEffect(() => {
    if (isNotNil(recordId) && !attachmentsLoading) {
      setAttachmentsCount(attachmentsCount);
    }
  }, [recordId, attachmentsLoading, attachmentsCount]);
  
  const handleUpsert = async (data: AttachmentInput) => {
    const payload: AttachmentInput = {
      pKey: data?.pKey,
      docTitle: data?.docTitle,
      fldAltPath: data?.fldAltPath,
      fldDescription: data?.fldDescription,
      fldFileName: data?.fldFileName || uploadedFile.key,
      fldLibType: data?.fldLibType || null,
      fldNotes: data?.fldNotes,
      fldPage: data?.fldPage,
      fldRevision: data?.fldRevision,
      fldRevisionDate: data?.fldRevisionDate,
      fldShowInCentral: data?.fldShowInCentral,
      fldSrhkey: fldSrhkey || null,
      recordId,
      recordType,
    }

    const isCreate = isNil(payload.pKey)
    const { responseData, responseMessage} = await upsertAttachment(payload, isCreate);

    setSelectedDocument(undefined);
    setIsVisible(false);
    setUploadedFile(defaultFile);
    setReloadPhotos(!reloadPhotos);

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDelete = async (data: Attachment) => {

    if (!data.pKey) {
      throw new Error("Attachment pKey is undefined");
    }

    if (!data.recordId) {
      throw new Error("Attachment recordId is undefined");
    }
  
    const payload: AttachmentCrossReferenceInput = {
      attachmentId: data.pKey,
      recordId: data.recordId,
    };

    const { responseData, responseMessage} = await deleteAttachment(payload);

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleAddClick = () => {
    setSelectedDocument(undefined);
    setIsVisible(true);
    setUploadedFile(defaultFile);
  };

  const onFormCancel = () => {
    setSelectedDocument(undefined);
    setIsVisible(false);
    setUploadedFile(defaultFile);
  };

  const onSelect = (data: Attachment) => {
    setSelectedDocument(data);
    setIsVisible(true);
  };

  return (
    <>
      <div>
        <div className="flex justify-end mb-4">
          <Button 
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
            disabled={readOnly}
          >
            Add
          </Button>
        </div>
         <AttachmentGrid
          documents={attachmentsData}
          recordId={recordId}
          attachmentType={attachmentType}
          loading={attachmentsLoading}
          onSelect={onSelect}
          onDelete={handleDelete}
          readOnly={readOnly}
        />
      </div>

      <AttachmentPopup
        visible={isVisible}
        document={selectedDocument}
        attachmentType={attachmentType}
        onCancel={onFormCancel}
        onSubmit={handleUpsert}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        saveLoading={upsertAttachmentLoading}
        disableCheckbox={disableCheckbox}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default AttachmentTab;
