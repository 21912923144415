import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, omit, isArray, isString } from 'lodash';
import { useAppState } from 'src/contexts/app-state';
import { DeleteTwoTone } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { Subscription } from 'rxjs';
import { useForm } from 'react-hook-form';
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import { Certificate } from '../../../generated/graphql';
import CertificateSummaryForm from './component/CertificateSummaryForm';
import { getDatabase } from '../../../rxdb';
import { CHAR_LIMIT } from '../../../consts';
import StickyAppBar from '../../UI/StickyAppBar';
import { validateForm } from './utils';
import WarningDialog from 'src/components/UI/WarningDialog';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { handleCharLimitWarning } from 'src/utils';
import AttachmentTab from 'src/modules/Attachments';
import { AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import Comments from 'src/modules/Comments';

interface Props {
  initialValue: CertificateDocument;
  onCancel: () => void;
  onSave: (issue: Certificate, isCreate: boolean) => void;
  onDelete: (issue: CertificateDocument) => void;
  onUndo?: () => void;
  onCertificateCreate?: () => void;
  isCreate?: boolean;
  certificateType?: any;
  type?: string;
  isPassport?: boolean;
}

const CertificatesDetail: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  onCertificateCreate,
  isCreate = false,
  certificateType,
  isPassport = false,
  type,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      PKey: initialValue.PKey,
      fldType: initialValue.fldType || '',
      fldCertNum: initialValue.fldCertNum || '',
      fldNotes: initialValue.fldNotes || '',
      fldSMS: initialValue.fldSMS || false,
      DateIssue: normalizeDateFormValue(initialValue.DateIssue),
      DateExpire: normalizeDateFormValue(initialValue.DateExpire),
      fldNationality: initialValue.fldNationality || '',
      fldIssue: initialValue.fldIssue || '',
      fldCertType: initialValue.fldCertType || 0,
      fldArchive: initialValue.fldArchive || false,
      fldTimeWarn: initialValue.fldTimeWarn || null,
      fldTimeWarnMeasure: initialValue.fldTimeWarnMeasure || null,
      fldResponsible: initialValue.fldResponsible || null,
      fldCrewID: initialValue.fldCrewID || null,
      fldSRHKey: initialValue.fldSRHKey,
    },
  });

  const [oldItemUndo, setOldItemUndo] = useState<Certificate[]>([]);
  const [currentTab, setTab] = useState(0);
  const [item, setItem] = useState<Certificate>(initialValue.toJSON());
  const [isEdited, setEdited] = useState<boolean>(false);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const [initialDefaultVlaue, setInitialDefaultValue] = useState<any>([]);
  const formInitialValues = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const { settingsPersonal } = useAppState();
  const { CERTIFICATES } = CHAR_LIMIT;

  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();

  const [editFlag, setEditFlag] = useState(false);
  const [cannotViewFlag, setCannotViewFlag] = useState(false);

  const setPermission = async ()=>{
    if(settingsPersonal.fldCerts === 1){
      setEditFlag(true)
    }
    if(settingsPersonal.fldCerts === 0 || isNil(settingsPersonal.fldCerts)){
      setCannotViewFlag(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setPermission()
    }
  },[settingsPersonal])

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };
    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    setInitialValues();
    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = (name: string, value: any) => {
    let shouldDirty = true;
    if (name === 'fldSRHKey') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldSRHKey === updatedValue) {
        shouldDirty = false;
      }
    }
    setValue(name, value, { shouldDirty: shouldDirty });
    setItem(getValues())
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar, certificateType)) return;
    const db = await getDatabase();
    const {
      fldType,
      fldCertNum,
      fldNotes,
      fldSMS,
      DateIssue,
      DateExpire,
      fldNationality,
      fldIssue,
      fldCertType,
      fldArchive,
      fldTimeWarn,
      fldTimeWarnMeasure,
      fldResponsible,
      fldCrewID,
      fldSRHKey,
    } = data;

    const document = {
      ...item,
      fldType,
      fldCertNum,
      fldNotes,
      fldSMS,
      DateIssue: normalizeDateTime(DateIssue),
      DateExpire: normalizeDateTime(DateExpire),
      fldNationality: isString(fldNationality)
      ? fldNationality
      : fldNationality?.fldMember || null,
      fldIssue,
      fldCertType,
      fldArchive,
      fldResponsible: isString(fldResponsible)
        ? fldResponsible
        : fldResponsible?.fldMember || null,
      fldTimeWarn: parseInt(fldTimeWarn, 10) || null,
      fldTimeWarnMeasure: fldTimeWarnMeasure?.measureValue || null,
      fldCrewID : isString(fldCrewID)
      ? fldCrewID
      : fldCrewID?.fldCrewID || null,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      updatedAt: new Date().toISOString(),
      PKey: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
    } as any;

    try {
      setOldItemUndo([]);
      const doc = omit(document,['fldPassport'])
      const res = await db.collections.certificates.upsert(doc);

      onSave(res, isCreate);

      if (isCreate) {
        onCertificateCreate && onCertificateCreate();
      }
      await setInitialDefaultValue(getValues());
      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  }

  const handleCancel = () => {
    if (isCreate) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar, certificateType)) return;
    if (isEditing) return; // We will send submit action that will be handled in HandleSave.
    onCancel();
  };

  if (isNil(item)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreate;

  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <form
      id="Certificates-Edit-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            {(editFlag || cannotViewFlag) && (
              <RecordEditWarningCard />
            )}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Type/Title',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: CERTIFICATES.fldType }}
                warning={(value) => handleCharLimitWarning(value, CERTIFICATES.fldType)}
                control={control}
                name="fldType"
              />
            </div>
          </div>

          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <CertificateSummaryForm
                      control={control}
                      initialValue={initialValue}
                      form={item}
                      onChange={onChange}
                      passport={isPassport}
                      certificateType={certificateType}
                      isCreated={isCreate}
                    />
                  ),
                },
                {
                  // label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                  label: `Attachments`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.PKey}
                      recordType={certificateType === 'CREWPROFILE' ? RecordType.CrewCertificates : RecordType.VesselCertificates} // ?????
                      recordTypeName={certificateType === 'CREWPROFILE' ? 'CrewCertificates' : 'VesselCertificates'} // when refactor to .net api, add proper object.__typename for counts cache update
                      attachmentType={AttachmentType.Document}
                      fldSrhkey={initialValue.fldSRHKey}
                      setAttachmentsCount={setDocumentsCount}
                      readOnly={editFlag || cannotViewFlag} 
                      disableCheckbox={true}
                    />
                  ),
                },
                {
                  // label: `Photos (${photosCount})`,
                  label: `Photos`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.PKey}
                      recordType={certificateType === 'CREWPROFILE' ? RecordType.CrewCertificates : RecordType.VesselCertificates}
                      recordTypeName={certificateType === 'CREWPROFILE' ? 'CrewCertificates' : 'VesselCertificates'} // when refactor to .net api, add proper object.__typename for counts cache update
                      attachmentType={AttachmentType.Photo}
                      fldSrhkey={initialValue.fldSRHKey}
                      setAttachmentsCount={setPhotosCount}
                      readOnly={editFlag || cannotViewFlag} 
                      disableCheckbox={certificateType === 'CREWPROFILE'? true: false}
                    />
                  ),
                },
                {
                  // label: `Comments (${commentsCount})`,
                  label: `Comments`,
                  disabled: isCreate,
                  component: (
                    <Comments
                      recordId={initialValue.PKey}
                      recordType={certificateType === 'CREWPROFILE' ? RecordType.CrewCertificates : RecordType.VesselCertificates}
                      recordTypeName={certificateType === 'CREWPROFILE' ? 'CrewCertificates' : 'VesselCertificates'} // when refactor to .net api, add proper object.__typename for counts cache update
                      setCommentsCount={setCommentsCount}
                      readOnly={editFlag || cannotViewFlag}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? 'Save' : 'Close'}
          onOk={() => handleOk(isEditing)}
          disabled={editFlag && isEditing}
          onCancel={isEditing ? () => handleCancel() : undefined}
        >
          {!isCreate && !editFlag && !isNil(item.PKey) && (
            <IconButton
              onClick={handleDelete}
              hidden={!!item.PKey}
              color="error"
              aria-label="Delete task"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default CertificatesDetail;
