import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, isArray } from 'lodash';
import { useForm } from 'react-hook-form';
import { DeleteTwoTone, VisibilityOff, Close } from '@mui/icons-material';
import { Alert, AlertColor, Backdrop, Box, IconButton, Snackbar, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import RelatedTab from './component/RelatedTab';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import { CHAR_LIMIT, LIBRARY } from '../../../consts';
import { handleCharLimitWarning } from 'src/utils';
import { validateForm } from './utils';
import { Documentation, RecordType } from 'src/generated/dotnet.graphql';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';
import DocumentationSummaryForm from './component/DocumentationSummaryForm';
import Comments from 'src/modules/Comments';

const useStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: 900,
  },
}));

interface DocumentationDetailFormProps extends Partial<InjectedDrawerProps> {
  initialValue: Documentation;
  recordType: RecordType;
  isCreate?: boolean;
  onSave: (responseData: Documentation, responseMessage: string, isCreated: boolean) => void;
  onDelete?: (responseData: Documentation, responseMessage: string) => void;
  onShowInCentral: (responseData: Documentation, responseMessage: string) => void;
  onCancel: () => void;
  libraryType: any;
  moduleReadOnly?: boolean;
}

const DocumentationDetailForm: FC<DocumentationDetailFormProps> = ({
  initialValue,
  recordType,
  onCancel,
  onSave,
  onDelete,
  onShowInCentral,
  libraryType,
  isCreate=false,
  moduleReadOnly = false,
  setFormIsDirty,
}) => {
  const { control, setValue, handleSubmit, getValues, reset, watch, formState } = useForm<any>({
    defaultValues: {
      docTitle: initialValue.docTitle || '',
      description: initialValue.description || '',
      docPage: initialValue.docPage || null,
      docFigure: initialValue.docFigure || null,
      addedBy: initialValue.addedBy || null,
      toc: initialValue.toc || null,
      number: initialValue.number || null,
      specRef: initialValue.specRef || null,
      password: initialValue.password || null,
      libType: initialValue.libType || null,
      fileType: initialValue.fileType || null,
      status: initialValue.status || null,
      statusInternal: initialValue.statusInternal || null,
      categoryId: initialValue.categoryId,
      smsReport: initialValue.smsReport || false,
      received: initialValue.received || false,
      showInCentral: initialValue.showInCentral || true,
      completeReqd: initialValue.completeReqd || false,
      verifiedReq: initialValue.verifiedReq || false,
    },
  });

  const { user } = useAuth();
  const { settingsPersonal, syncRxdb } = useAppState();
  const formInitialValues = useRef<any>({});
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showInCentral, setShowInCentral] = useState(false);
  const [open, setOpen] = useState(false);
  const { TBLDOCUMENTATION } = CHAR_LIMIT;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    setInitialValues();
    return () => {
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const handleSave = async (data: any) => {

    if (!validateForm(data, setSnackbar)) return;

    const {
      docTitle,
      description,
      docPage,
      docFigure,
      addedBy,
      toc,
      number,
      specRef,
      password,
      fileType,
      status,
      statusInternal,
      categoryId,
      smsReport,
      received,
      showInCentral,
      completeReqd,
      verifiedReq,
    } = data;

    const document = {
      ...initialValue,
      docTitle,
      description,
      docPage,
      docFigure,
      addedBy,
      toc,
      number,
      specRef,
      password,
      fileType,
      status,
      statusInternal,
      categoryId: (isArray(categoryId) ? last(categoryId) : categoryId) || null,
      smsReport,
      received,
      showInCentral,
      completeReqd,
      verifiedReq,
      libtype: libraryType
    } as any;

    // const { responseData, responseMessage } = await upsertLogEntry(document, isCreate, queryVariables, callerComponent);

    // if (responseData) {
    //   onSave(responseData as LogEntry, responseMessage, isCreate);

    //   const updatedFormValues = { 
    //     ...getValues(),
    //     fldHours: responseData?.fldHours,
    //   };     
    //   reset(updatedFormValues);
    // }

    // syncRxdb(); // TODO - Temporary solution for hybrid functionality with rxdb - remove after refactor to .net api 
  };

  const handleShowInCentral = async () => {
    setShowInCentral(true);
  };

  const handleShowInCentralOk = async () => {
    // const document = {
    //   ...initialValue,
    //   showInCentral: false,
    // } as any;

    // const { responseData, responseMessage } = await upsertDocument(document, queryVariables, callerComponent);
    // responseData && onShowInCentral && onShowInCentral(responseData, responseMessage);
    setShowInCentral(false);
  };

  const handleShowInCentralCancel = async () => {
    setShowInCentral(false);
  };

  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = () =>{
    // const { responseData, responseMessage } = await removeLogEntry(initialValue.pkey, queryVariables, callerComponent);
    // responseData && onDelete && onDelete(responseData, responseMessage);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  }

  const handleCancel = () => {
    if (isCreate) {
      setFormIsDirty && setFormIsDirty(false)
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar)) return;
    if (isEditing) return;
    onCancel();
  };

  const hasValuesBeenChanged = formState.isDirty && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);
  const isEditing = hasValuesBeenChanged || isCreate;

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const actions = [
    {
      icon: < VisibilityOff style={{ color: '#002041' }} />,
      name: 'Hide from Libraries',
      onclick: handleShowInCentral,
    },
  ];

  const classes = useStyles();

  return (
    <form
      className="relative bg-white pt-14 md:pt-19 flex-grow"
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            {moduleReadOnly && (
              <RecordEditWarningCard recordType={libraryType} />
            )}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Document Title',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: TBLDOCUMENTATION.DocTitle }}
                warning={(value) => handleCharLimitWarning(value, TBLDOCUMENTATION.DocTitle)}
                control={control}
                name="docTitle"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Number',
                  variant: 'standard',
                }}
                rules={{ maxLength: TBLDOCUMENTATION.fldNumber }}
                warning={(value) => handleCharLimitWarning(value, TBLDOCUMENTATION.fldNumber)}
                control={control}
                name="number"
              />
            </div>
          </div>

          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <DocumentationSummaryForm
                      watch={watch}
                      control={control}
                      onChange={onChange}
                      libraryType={libraryType}
                      isCreate={isCreate}
                      readOnly={moduleReadOnly}
                    />
                  ),
                },
                ...(!isCreate ? 
                  [
                    {
                      label: 'Related Items',
                      component: (
                        <RelatedTab
                          keyValue={initialValue.id}
                          keyName={initialValue.docTitle}
                        />
                      ),
                    },
                    {
                      label: `Comments (${commentsCount})`,
                      disabled: isCreate,
                      component: (
                        <Comments
                          recordId={initialValue.id}
                          recordType={recordType}
                          recordTypeName={initialValue.__typename!}
                          setCommentsCount={setCommentsCount}
                          readOnly={moduleReadOnly}
                        />
                      ),
                    },
                  ] : []
                ),
              ]}
            />
          </div>
        </div>
      </div>

      <StickyAppBar
        cancelText="Cancel"
        okType={isEditing ? 'submit' : 'button'}
        okText={isEditing ? (isCreate ? 'Create' : 'Save') : 'Close'}
        onOk={() => handleOk(isEditing)}
        disabled={moduleReadOnly && isEditing}
        onCancel={isEditing ? () => handleCancel() : undefined}
      >
        { !isCreate && 
          !moduleReadOnly && 
          !isNil(initialValue.id) && 
          libraryType !== LIBRARY.SMS.TYPE && 
          libraryType !== LIBRARY.ISPS.TYPE && (
            <Box sx={{ position: 'relative', height: 70 }}>
              <Backdrop open={open} />
              <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 12, right: 12 }}
                FabProps={{ size: 'small' }}
                icon={(
                  <SpeedDialIcon
                    sx={{ fontSize: 'small' }}
                    icon={<SpeedDialIcon />}
                    openIcon={<Close />}
                  />
                )}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    classes={classes}
                    onClick={action.onclick}
                  />
                ))}
              </SpeedDial>
            </Box>
          )
        }
        
        {!moduleReadOnly && !isNil(initialValue.id) && (
          <IconButton
            onClick={handleDelete}
            color="error"
            aria-label="Delete item"
          >
            <DeleteTwoTone />
          </IconButton>
        )}
      </StickyAppBar>

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <WarningDialog
        visible={showInCentral}
        title="Hide from Doc Libraries Warning"
        content="Are you sure you wish to hide record from Doc Libraries?"
        okText='Yes'
        color='info'
        onOk={handleShowInCentralOk}
        onCancel={handleShowInCentralCancel}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </form>
  );
};

export default DocumentationDetailForm;
