import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, omit, isString } from 'lodash';
import moment from 'moment';
import { handleCharLimitWarning } from 'src/utils';
import { useForm } from 'react-hook-form';
import { DeleteTwoTone } from '@mui/icons-material';
import { Portal, Alert, IconButton, Snackbar } from '@mui/material';
import { Subscription } from 'rxjs';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';
import { getDatabase } from '../../../rxdb';
import { CHAR_LIMIT } from '../../../consts';
import { Orders } from '../../../generated/graphql';
import OrdersSummaryForm from './component/OrdersSummaryForm';
import { validateForm } from './utils';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import PrintButtonOld from 'src/components/UI/PrintButton/indexOld';
import PurchaseReport from 'src/components/UI/PDF/reports/PurchasingReportsPDF/defaultPO';
import { TblShipsParticularsDocument } from 'src/api/queries/tblShipsParticulars/rxdb';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';
import AttachmentTab from 'src/modules/Attachments';
import { AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import Comments from 'src/modules/Comments';

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: OrdersDocument;
  onCancel: () => void;
  onSave: (orderItem: Orders, isCreated: boolean) => void;
  onDelete: (orderItem: OrdersDocument) => void;
  onUndo?: () => void;
  moduleReadOnly: boolean;
  type?: string;
  isCreate: boolean;
  orderItemsUpdate: () => void;
}

const PurchaseDetailForm: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  moduleReadOnly = false,
  isCreate,
  type,
  orderItemsUpdate,
  setFormIsDirty,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      PurchaseOrderNumber: initialValue.PurchaseOrderNumber || '',
      OrderNumber: initialValue.PurchaseOrderNumber || '',
      OrderName: initialValue.OrderName,
      fldStatus: initialValue.fldStatus || null,
      fldType: initialValue.fldType,
      Supplier: initialValue.Supplier,
      SupplierID: initialValue.SupplierID || null,
      Department: initialValue?.Department || null,
      OrderedBy: initialValue?.OrderedBy || null,
      fldHTML: initialValue.fldHTML || '',
      NoView: initialValue.NoView || false,
      OrderSent: initialValue.OrderSent || false,
      PromisedByDate: normalizeDateFormValue(initialValue.PromisedByDate),
      OrderDate: normalizeDateFormValue(initialValue.OrderDate),
    },
  });

  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const [orderItem, setOrdersItem] = useState<Orders>(initialValue.toJSON());
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [POItems, setPOItems] = useState<any[]>([]);
  const [isPOItemsReady, setIsPOItemsReady] = useState(false);
  const [vesselSpecs, setVesselSpecs] = useState<TblShipsParticularsDocument>();
  const { ORDERS } = CHAR_LIMIT;

  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();

  const setRecordReadOnlyPermission = async ()=>{
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.Department){
      setRecordReadOnly(true)
    }
    if(!settingsPersonal.fldDeptHead) {
      setRecordReadOnly(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setRecordReadOnlyPermission();
    }
  },[settingsPersonal])

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const handlePOItems = (data: any) => {
    setPOItems(data);
    setIsPOItemsReady(true)
  }

  const setVesselData = async () => {
    const db = await getDatabase();
    const result = await db.tblshipsparticulars.find().exec();
    setVesselSpecs(result[0]);
  };

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    setInitialValues();
    setVesselData();
    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    if (name === 'Supplier') {
      setValue(
        name,
        { DisplayMember: value.DisplayMember },
        { shouldDirty: shouldDirty }
      );
      return;
    }

    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    onCancel();
  };

  const extractContent = (s: string) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;
    const db = await getDatabase();

    // Create items before creating Item.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection'])
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      OrderDate,
      OrderName,
      PurchaseOrderNumber,
      Supplier,
      SupplierID,
      Department,
      OrderedBy,
      Contact,
      NoView,
      fldHTML,
      OrderSent,
      fldType,
      fldStatus,
      PromisedByDate,
    } = data;
    
    const document = {
      ...orderItem,
      OrderName,
      OrderDate : moment(OrderDate).isValid() ? normalizeDateTime(OrderDate) : new Date().toISOString(),
      Supplier: typeof Supplier === 'object' ? Supplier?.DisplayMember : Supplier || null,
      Contact,
      fldHTML,
      PurchaseOrderNumber,
      OrderedBy: typeof OrderedBy === 'object' ? OrderedBy?.name : OrderedBy || null,
      Department: isString(Department)
        ? Department
        : Department?.fldMember || null,
      fldType,
      fldStatus: isString(fldStatus)
      ? fldStatus
      : fldStatus?.fldMember || null,
      PromisedByDate,
      MyDesc: extractContent(fldHTML),
      NoView,
      OrderSent,
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (ProductID)
      OrderID: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      const res = await db.collections.orders.upsert(document);
      onSave(res, isCreate);
      const dataValue = getValues();
      dataValue.Supplier =
        typeof Supplier === 'object' ? Supplier?.DisplayMember : null;
      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    setIsDeleting(true);
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };

  const handleOrderItemsUpdate = (isUpdated: boolean) => {
    if (isUpdated) {
      orderItemsUpdate();
    }
  };

  const handleCancelUndo = () => {
    if (isCreate) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.
    handleCancel();
  };

  if (isNil(orderItem)) return null;

  const hasValuesBeenChanged =
    formState.isDirty &&
    (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreate;

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);
  
  const formClass =
    type === 'Dialog'
      ? 'relative bg-white flex-grow'
      : 'relative bg-white pt-14 md:pt-19 flex-grow';

  const handleGeneratePDF = () => {
    const data = {
      PO: initialValue,
      POItems: POItems,
      VesselSpecs: vesselSpecs,
    }

    return PurchaseReport(data);
  };

  return (
    <form
      id="Inventory-Edit-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full flex flex-col">
          <div className="mb-6">
            {(moduleReadOnly || recordReadOnly) && (
              <RecordEditWarningCard />
            )}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Order Name',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: ORDERS.OrderName }}
                warning={(value) => handleCharLimitWarning(value, ORDERS.OrderName)}
                control={control}
                name="OrderName"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Order Number',
                  variant: 'standard',
                }}
                rules={{ maxLength: ORDERS.PurchaseOrderNumber }}
                warning={(value) => handleCharLimitWarning(value, ORDERS.PurchaseOrderNumber)}
                defaultValue={initialValue.PurchaseOrderNumber}
                control={control}
                name="PurchaseOrderNumber"
              />
            </div>

            <div className="mt-3">
              <CompaniesDropdownOld
                control={control}
                label="Supplier"
                name="Supplier"
                onChange={onChange}
                variant="standard"
                size="small"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Contact Person',
                  variant: 'standard',
                }}
                defaultValue={initialValue.Contact}
                control={control}
                name="Contact"
              />
            </div>
          </div>

          <div className="mt-3">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <OrdersSummaryForm
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      setPOItems={handlePOItems}
                      form={orderItem}
                      onChange={onChange}
                      getValues={getValues}
                      onOrderItemUpdate={handleOrderItemsUpdate}
                      disableEdit = {recordReadOnly}
                      editFlag={moduleReadOnly}
                    />
                  ),
                },
                {
                  // label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                  label: `Attachments`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.OrderID}
                      recordType={RecordType.PurchaseOrder}
                      recordTypeName={'PurchaseOrder'} // when refactor to .net api, add proper object.__typename for counts cache update
                      attachmentType={AttachmentType.Document}
                      setAttachmentsCount={setDocumentsCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  // label: `Photos (${photosCount})`,
                  label: `Photos`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.OrderID}
                      recordType={RecordType.PurchaseOrder}
                      recordTypeName={'PurchaseOrder'} // when refactor to .net api, add proper object.__typename for counts cache update
                      attachmentType={AttachmentType.Photo}
                      setAttachmentsCount={setPhotosCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  // label: `Comments (${commentsCount})`,
                  label: `Comments`,
                  disabled: isCreate,
                  component: (
                    <Comments
                      recordId={initialValue.OrderID}
                      recordType={RecordType.PurchaseOrder}
                      recordTypeName={'PurchaseOrder'} // when refactor to .net api, add proper object.__typename for counts cache update
                      setCommentsCount={setCommentsCount}
                      readOnly={moduleReadOnly || recordReadOnly}
                    />
                  ),
                }
              ]}
            />
          </div>
        </div>
      </div>
      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? 'Save' : 'Close'}
          onOk={() => handleOk(isEditing)}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
          disabled={(moduleReadOnly || recordReadOnly) && isEditing}
        >
          {!isCreate &&  (
            <PrintButtonOld
              generatePDF={handleGeneratePDF}
              enable={isPOItemsReady}
              title='Print PO'
            />
          )}

          {!isCreate && !recordReadOnly && !moduleReadOnly && !isNil(orderItem.OrderID) && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText="Yes"
        color="error"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      
      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </form>
  );
};

export default PurchaseDetailForm;
