import React, { useState, FC, useCallback, MutableRefObject } from 'react';
import { isNil, pick, size } from 'lodash';
import { uuid } from 'uuidv4';
import { Alert, Box, Dialog, IconButton, Snackbar } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';
import { getDatabase } from 'src/rxdb';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { Add, ArrowDownward, ArrowUpward, DeleteTwoTone, Download, Edit } from '@mui/icons-material';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { OrderItemsDocument } from 'src/rxdb/collections/OrderItems/schema';
import WarningDialog from 'src/components/UI/WarningDialog';
import IconBarCode from 'src/assets/icon-bc-primary.svg';
import InventoryDetailForm from 'src/components/dataentry/inventoryDE/InventoryDetailForm';
import BeveragesDetailForm from 'src/components/dataentry/invbeveragesDE/BeveragesDetailForm';
import UniformsDetailForm from 'src/components/dataentry/invuniformsDE/UniformsDetailForm';
import DryStoresDetailForm from 'src/components/dataentry/invdrystoresDE/DryStoresDetailForm';
import MedicalInventoryDetailForm from 'src/components/dataentry/invmedicalDE/MedicalInventoryDetailForm';
import { MultipleLocationDocument } from 'src/rxdb/collections/MultipleLocations/schema';
import { ReceivedSelectedItemEnum } from './OrderItemsAddDialog'

interface Props {
  initialValue: OrdersDocument;
  onDelete: (item: OrderItemsDocument) => void;
  loadingGrid: boolean;
  onSelect: (item: OrderItemsDocument, receiveItemOption?: ReceivedSelectedItemEnum | undefined) => void;
  onSave?: (item: any, isCreated?: boolean) => void;
  setPOItems: (data: any) => void;
  editFlag?: boolean;
  disableEdit: boolean;
}

const transformData = async (item: any) => ({
  ...pick(item, ['PKey', 'ProductID', 'ProductName', 'Amount', 'AmtReceived']),
  original: item,
});

const OrderItemGrid: FC<Props> = ({
  initialValue,
  onDelete,
  loadingGrid,
  onSelect,
  setPOItems,
  onSave,
  editFlag = false,
  disableEdit = false,
}) => {
  const [loading, setLoading] = useState<boolean>(loadingGrid);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const [inventory, setInventory] = useState<InventoryDocument[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedItemOrderID, setSelectedItemOrderID] = useState<string>("");
  const [selectedItemToUpdate, setSelectedItemToUpdate] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuItem, setMenuItem] = React.useState<null | MultipleLocationDocument>(null);
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();
    let inventoryList = await db.inventory
      .find({
        selector: {
          deletedAt: null,
          deletedBy: {
            $eq: null
          },
        },
      })
      .exec();
    setInventory([...inventoryList]);
    const items = await db.orderitems
      .find({
        selector: {
          OrderID: initialValue.OrderID,
          deletedBy: {
            $eq: null
          },
        },
        
      })
      .exec();

    items.sort((a: OrderItemsDocument, b: OrderItemsDocument) => a.updatedAt < b.updatedAt ? 1 : -1);
    const length = size(items);
    const data = await Promise.all(items.map(transformData));
    setPOItems(items);
    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.orderitems.$.subscribe(async (ev: any) => {
      ref.current?.reload();
    });

    db.inventory.$.subscribe(async (ev: any) => {	
      ref.current?.reload();	
    });

    db.tblmultiplelocations.$.subscribe(async (ev: any) => {
      ref.current?.reload();
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    setLoading(status);
  };

  const rowDeletePress = (item: any) => {
    setDeleteSelected(item.original);
    setIsDeleting(true);
  };

  const handleDeleteOk = () => {
    onDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const rowEdit = (item: any, receiveItemOption: ReceivedSelectedItemEnum| undefined) => {
    onSelect(item.original, receiveItemOption);
  };

  const handleCancel = () => {
    setSelectedItem(undefined);
    setSelectedItemToUpdate(undefined);
  };

  const handleDelete = async (item: InventoryDocument) => {
    try {
      await item.remove();
      setSelectedItem(undefined);
      setSnackbar({
        open: true,
        message: 'Inventory item successfully removed',
        type: 'success',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  };

  const onClickAddItem = async (item: any) => {
    setSelectedItemOrderID(item.original.OrderID);
    const db = await getDatabase();
    const inventoryItem: any = await db.inventory
      .find({
        selector: {
          ProductID: item.ProductID,
        },
      })
      .exec();
    if (!item.ProductID || inventoryItem.length === 0) {
      setIsNewProduct(true);
      let document = {
        ProductID: item.original.ProductID || uuid(),
        ProductName: item.original.ProductName,
        ProductDescription: item.original.ProductDescription,
        ModelNumber: item.original.ModelNumber,
        Department: item.original.Department || initialValue.Department,
        Manufacturer: item.original.Manufacturer,
        Qty: item.original.Qty,
        fldPartNumber: item.original.PartNumber,
        fldDataType: item.original.fldDataType,
        fldSize: item.original.fldSize,
        fldColor: item.original.fldColor,
      };
      setSelectedItem(document);
      setSelectedItemToUpdate(item.original);
    }
    else {
      setIsNewProduct(false);
      setSelectedItem(inventoryItem[0]);
    }
  }

  const onSaveItem = async (data: any, isCreated: any) => {
    let resp;
    if (selectedItemToUpdate) {
      const oldData = selectedItemToUpdate.toJSON();
      const newData = data.toJSON();
      const db = await getDatabase();
      
      let totalAmtReceived: number = 0;
      let locationsItems: any = await db.tblmultiplelocations
            .find({
              selector: {
                ProductID: selectedItem.ProductID,
              },
            })
            .exec();
      if(locationsItems && locationsItems.length > 0) {
        for (let i in locationsItems) {
          const locationsItem: any = JSON.parse(JSON.stringify(locationsItems[i]));
          totalAmtReceived += locationsItem.Amount;
          locationsItem.ProductID = newData.ProductID;
          delete locationsItem.updatedAt;
          delete locationsItem.deletedBy;
          delete locationsItem.deletedAt;
          delete locationsItem.isRecoverable;
          await db.tblmultiplelocations.upsert(locationsItem);
        }
      }

      resp = await db.orderitems.upsert({
        ...oldData,
        AmtReceived: totalAmtReceived,
        ProductID: newData.ProductID,
      });
    }
    
    // onSave && onSave(resp, isNewProduct);
    // handleCancel();
    // Note: Below code is for to keep open dialog for new item create
    if(!isNewProduct){
      onSave && onSave(resp, isNewProduct);
      handleCancel();
    } else{
      const data = await transformData(resp);
      setSnackbar({
        open: true,
        message: 'Item has been created!',
        type: 'success',
      });
      onClickAddItem(data)
    }
  }

  const dataSource = useCallback(loadData, []);

  const openMenu = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setMenuItem({ ...data });
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (mode: any) => {
    rowEdit(menuItem, mode);
    setAnchorEl(null);
  };

  const columns: any[] = [
    {
      defaultWidth: 50,
      render: ({ data }: any) => {
        const showBarCodeIcon: boolean = (data.ProductID && inventory.find((i: InventoryDocument) => i.ProductID === data.ProductID)) ?? false;
        let arrowUpIcon: boolean = false;
        let arrowDownIcon: boolean = false;

        if (data.Amount && data.AmtReceived) {
          arrowDownIcon = data.AmtReceived < data.Amount;
          arrowUpIcon = data.AmtReceived > data.Amount;
        }

        return (
          <div>
            {showBarCodeIcon && <img src={IconBarCode} alt="" style={{ cursor: "pointer" }} onClick={() => onClickAddItem(data)} />}
            {arrowDownIcon && <ArrowDownward color="error" fontSize="medium" />}
            {arrowUpIcon && <ArrowUpward color="success" fontSize="medium" />}
          </div>
        )
      }
    },
    {
      name: 'ProductName',
      header: 'Product',
      flex: 1,
    },
    {
      name: 'Amount',
      header: 'Amount',
      defaultWidth: 100,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
    },
    {
      header: 'Rec\'d',
      defaultWidth: 95,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      render: ({data}: any)=>{
        return (<Box>{ isNil(data.AmtReceived)? 0 :data.AmtReceived} </Box>)
      }
    },
    {
      id: 'icons',
      defaultWidth: 130,
      render: ({ data }: any) => {
        const receivedIconShow: boolean = (data.ProductID && inventory.find((i: InventoryDocument) => i.ProductID === data.ProductID)) ?? false;
        return (
        <Box display={"flex"} flexWrap="wrap" justifyContent={'center'}>
          <IconButton
            onClick={() => rowEdit(data, undefined)}
            size="small"
            color="primary"
            aria-label="Edit item"
            disabled={disableEdit || editFlag}
          >
            <Edit fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => rowDeletePress(data)}
            size="small"
            color="error"
            aria-label="Delete item"
            disabled={disableEdit || editFlag}
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
          {!receivedIconShow && 
            <IconButton
              onClick={() => onClickAddItem(data)}
              size="small"
              color="success"
              aria-label="Add item"
              disabled={disableEdit || editFlag}
            >
              <Add fontSize="inherit" />
            </IconButton>
          }
          {receivedIconShow && 
            <IconButton	
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, data)}
              size="small"	
              color="info"	
              aria-label="receive item"
              disabled={disableEdit || editFlag}
            >	
              <Download fontSize="inherit" />	
            </IconButton>
          }
        </Box>
      )},
    }
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow m-2">
        <LicensedReactDataGrid
          onLoadingChange={onLoadingChange}
          onReady={onReady}
          rowHeight={40}
          loading={loading}
          idProperty="PKey"
          columns={columns}
          dataSource={dataSource}
          groupByEnabled={false}
          showColumnMenuTool={false}
          rowClassName={({ data }: any) => {
            if (data.Amount && data.AmtReceived) {
              if (data.AmtReceived < data.Amount) return "text-red-600";
              if (data.AmtReceived > data.Amount) return "text-green-600";
            }
            return "";
          }}
          scrollProps={{
            autoHide: false,
          }}
          defaultSortInfo={[
            { name: 'ProductName', dir: 1 }
          ]}
          style={{ minHeight: "400px" }}
        />
      </div>
      <Menu
        id="receive-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={() => handleClose(ReceivedSelectedItemEnum.UseDefaults)}>Use Defaults</MenuItem>
        <MenuItem onClick={() => handleClose(ReceivedSelectedItemEnum.ChooseAmount)}>Choose Amount</MenuItem>
        <MenuItem onClick={() => handleClose(ReceivedSelectedItemEnum.ChooseLocationAndAmount)}>Choose Location & Amount</MenuItem>
      </Menu>
      <Dialog	
        scroll="paper"	
        fullWidth	
        maxWidth="md"	
        open={!!selectedItem}	
        onClose={handleCancel}	
      >
        {!!selectedItem && selectedItem.fldDataType === "0" && (
          <InventoryDetailForm
            initialValue={selectedItem}
            onCancel={handleCancel}
            onSave={onSaveItem}
            onDelete={handleDelete}
            moduleReadOnly={false}
            isCreate={isNewProduct}
            storageUpdate={() => { }}
            selectedItemOrderID={selectedItemOrderID}
          />
        )}
        {!!selectedItem && selectedItem.fldDataType === "1" && (
          <BeveragesDetailForm
            initialValue={selectedItem}
            onCancel={handleCancel}
            onSave={onSaveItem}
            onDelete={handleDelete}
            moduleReadOnly={false}
            isCreate={isNewProduct}
            storageUpdate={() => { }}
            selectedItemOrderID={selectedItemOrderID}
          />
        )}
        {!!selectedItem && selectedItem.fldDataType === "3" && (
          <UniformsDetailForm
            initialValue={selectedItem}
            onCancel={handleCancel}
            onSave={onSaveItem}
            onDelete={handleDelete}
            moduleReadOnly={false}
            isCreate={isNewProduct}
            storageUpdate={() => { }}
            selectedItemOrderID={selectedItemOrderID}
          />
        )}
        {!!selectedItem && selectedItem.fldDataType === "2" && (
          <DryStoresDetailForm
            initialValue={selectedItem}
            onCancel={handleCancel}
            onSave={onSaveItem}
            onDelete={handleDelete}
            moduleReadOnly={false}
            isCreate={isNewProduct}
            storageUpdate={() => { }}
            selectedItemOrderID={selectedItemOrderID}
          />
        )}
        {!!selectedItem && selectedItem.fldDataType === "4" && (
          <MedicalInventoryDetailForm
            initialValue={selectedItem}
            onCancel={handleCancel}
            onSave={onSaveItem}
            onDelete={handleDelete}
            moduleReadOnly={false}
            isCreate={isNewProduct}
            storageUpdate={() => { }}
            selectedItemOrderID={selectedItemOrderID}
          />
        )}
      </Dialog>
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText="Yes"
        color="error"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderItemGrid;
